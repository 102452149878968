export enum SolarEnvironmentVariable {
    IsHostedProd = 'SOLAR_IS_HOSTED_PROD',
    OcrEnabled = 'OCR_ENABLED',
    SolarSharepointUploadEnabled = 'SOLAR_SHAREPOINT_UPLOAD_ENABLED',
    HAS_OPENAI_API_KEY = 'HAS_OPENAI_API_KEY',
    ARE_AWS_CREDS_AVAILABLE = 'AWS_CREDS_AVAILABLE',
    INTERNAL_BUCKET_SET = 'INTERNAL_BUCKET_SET',
}

export type EnvironmentModels = {
    dateSynthesis: {
        runsOnGpu: boolean;
    };
    fasttext: {
        libVersion: string;
        model: string;
        runsOnGpu: boolean;
    };
    spacy: {
        libVersion: string;
        auxModel: {
            name: string;
            language: string;
            runsOnGpu: boolean;
            version: string;
        };
        multilingualModels: {
            name: string;
            language: string;
            runsOnGpu: boolean;
            version: string;
        }[];
    };
    torch: {
        gpuAvailable: boolean;
        libVersion: string;
    };
};

export type SolarEnvironmentVariables = Record<SolarEnvironmentVariable, unknown>;
