import { QuiSubmitButton, QuiFlexV, QuiFlexH, QuiForm, QuiPasswordField, QuiTextField, QuiLink } from '@tonicai/ui-quinine';
import { signIn } from '@/stores';
import { SignInFormValues } from '@/types';
import { SubmitError } from './SubmitError';
import { requiredEmail, requiredString } from '@/validation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Message } from '../Message/Message';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { isHostedProdAtom } from '@/stores';

export function SignInForm() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [linkTo, setLinkTo] = useState('');

    const [isHostedProd] = useAtom(isHostedProdAtom);

    useEffect(() => {
        const token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        sessionStorage.setItem('forgot_password_token', token);

        setLinkTo('/signup/forgotpassword?token=' + token);
    }, []);

    return (
        <QuiForm<SignInFormValues>
            onSubmit={(values: SignInFormValues) => {
                return signIn(values).then((response) => {
                    if (response === undefined) {
                        navigate('/');
                    } else {
                        return response;
                    }
                });
            }}
        >
            <QuiFlexV gap="md">
                {searchParams.get('accountCreated') !== null && (
                    <Message data-test="account-created-message" variant="success">
                        Account created! Please log in.
                    </Message>
                )}
                <QuiTextField data-test="signin-email-field" label="Email Address" validate={requiredEmail} name="userName" />
                <QuiFlexV gap="xs">
                    <QuiPasswordField
                        data-test="signin-password-field"
                        validate={requiredString}
                        hideShowPasswordToggle
                        label="Password"
                        name="password"
                    />
                    {isHostedProd && (
                        <QuiFlexH justify="left" gap="xs">
                            <QuiLink to={linkTo} size="text-sm" data-test="forgot-password-link" className="qui-link-currentcolor">
                                Forgot your password?
                            </QuiLink>
                        </QuiFlexH>
                    )}
                </QuiFlexV>
                <SubmitError />
                <QuiSubmitButton data-test="signin-submit-button" variant="brand-purple">
                    Log In
                </QuiSubmitButton>
            </QuiFlexV>
        </QuiForm>
    );
}
