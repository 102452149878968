import { useAtomValue } from 'jotai';
import { previewStatusAtom, previewDataAtom, blockTextContentEmptyAtom } from './state';
import { ReactNode, useMemo } from 'react';
import { QuiBox, QuiIcon, QuiSpinner } from '@tonicai/ui-quinine';
import styles from './styles.module.scss';
import { usePlaygroundContext } from './usePlaygroundContext';

type PreviewWrapperProps = Readonly<{
    children: ReactNode;
}>;

function PreviewWrapper({ children }: PreviewWrapperProps) {
    return (
        <QuiBox
            text="mono-text-lg"
            bg="background-neutral"
            border="stroke-base"
            borderRadius="md"
            padding="lg"
            gap="md"
            display="flex"
            flexDirection="column"
        >
            {children}
        </QuiBox>
    );
}

enum PreviewDisplay {
    Empty,
    Loading,
    PreviewResponse,
}

export function Preview() {
    const storeOptions = usePlaygroundContext();
    const responseStatus = useAtomValue(previewStatusAtom, storeOptions);
    const previewData = useAtomValue(previewDataAtom, storeOptions);
    const blockTextContentEmpty = useAtomValue(blockTextContentEmptyAtom, storeOptions);

    const previewDisplay = useMemo<PreviewDisplay>(() => {
        if (blockTextContentEmpty) return PreviewDisplay.Empty;

        if (!previewData) return PreviewDisplay.Loading;

        return PreviewDisplay.PreviewResponse;
    }, [blockTextContentEmpty, previewData]);

    if (responseStatus === 'error') {
        return (
            <PreviewWrapper>
                <QuiBox display="flex" alignItems="center" gap="sm" color="text-danger">
                    <QuiIcon size="sm" icon="alert-octagon" />
                    <span>Error fetching deidentification response</span>
                </QuiBox>
            </PreviewWrapper>
        );
    }

    if (responseStatus === 'loading' || previewDisplay === PreviewDisplay.Loading) {
        return (
            <PreviewWrapper>
                <QuiBox display="flex" alignItems="center" gap="sm">
                    <QuiSpinner size="sm" />
                    <span>Loading preview...</span>
                </QuiBox>
            </PreviewWrapper>
        );
    }

    if (!previewData || previewDisplay === PreviewDisplay.Empty) {
        return <PreviewWrapper>Results...</PreviewWrapper>;
    }

    if (typeof previewData === 'string') {
        return <PreviewWrapper>{previewData}</PreviewWrapper>;
    }

    return (
        <PreviewWrapper>
            {previewData.map((p) => (
                <p key={p.id}>
                    {p.chunks.map((c) => {
                        if (c.label && c.score) {
                            // If we ever add synthesize support, something like this will be needed:
                            // const redactedLabel = c.exampleRedaction ? c.exampleRedaction : c.label;
                            const redactedLabel = c.label;
                            return (
                                <span key={c.id} className={styles.previewRedaction}>
                                    {redactedLabel}
                                </span>
                            );
                        } else {
                            return <span key={c.id}>{c.text}</span>;
                        }
                    })}
                </p>
            ))}
        </PreviewWrapper>
    );
}
