import { Preview } from './Preview';
import {
    blockTextContentAtom,
    clearDeidResponse,
    blockTextOffsetAtom,
    updateBlockTextContent,
    responseStaleOrMissingAtom,
    deidResponseAtom,
} from './state';
import { QuiBox } from '@tonicai/ui-quinine';
import { useCallback, useEffect, useRef } from 'react';
import { EditorView } from 'prosemirror-view';
import { useAtomValue } from 'jotai';
import { AllSelection } from 'prosemirror-state';
import { createProsemirrorEditor, schema } from './prosemirror';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { DOMParser as ProseDOMParser } from 'prosemirror-model';
import { usePlaygroundContext } from './usePlaygroundContext';
import { Controls } from './Controls';

function htmlStringToProseMirrorNode(htmlString: string) {
    const dom = new DOMParser().parseFromString(htmlString, 'text/html');
    return new ProseDOMParser(schema, []).parse(dom);
}

export function Editor() {
    const storeOptions = usePlaygroundContext();

    // Atom values
    const deidResponse = useAtomValue(deidResponseAtom, storeOptions);
    const blockTextOffset = useAtomValue(blockTextOffsetAtom, storeOptions);
    const blockTextContent = useAtomValue(blockTextContentAtom, storeOptions);
    const responseStaleOrMissing = useAtomValue(responseStaleOrMissingAtom, storeOptions);

    // Refs for interacting with dom outside of React lifecycle
    const containerRef = useRef<HTMLDivElement | null>(null);
    const editorViewRef = useRef<EditorView | null>(null);

    const editorStateRef = useRef(createProsemirrorEditor());

    useEffect(() => {
        if (!containerRef.current) return;

        editorViewRef.current = new EditorView(containerRef.current, {
            state: editorStateRef.current,
            dispatchTransaction(tr) {
                const isPasteEvent = tr.getMeta('paste') === true;

                const editorView = editorViewRef.current;

                if (!editorView) return;

                editorView.updateState(editorView.state.apply(tr));

                const blockTextContent: string[] = [];
                editorView.state.doc.descendants((node) => {
                    if (node.type.name !== 'paragraph') return true;
                    blockTextContent.push(node.textContent);
                    return false;
                });
                updateBlockTextContent(storeOptions.store, blockTextContent, isPasteEvent);
            },
        });

        return () => {
            editorViewRef.current?.destroy();
            editorViewRef.current = null;
        };
    }, [storeOptions.store]);

    useEffect(() => {
        if (!editorViewRef.current) return;

        const transaction = editorViewRef.current.state.tr;

        if (transaction.doc.content.size > 0) {
            transaction.removeMark(0, transaction.doc.content.size, schema.mark('redaction'));
        }

        transaction.doc.descendants((node, position, _parent, index) => {
            if (node.type.name === 'paragraph' && node.isTextblock) {
                const blockOffset = blockTextOffset[index] ?? 0;
                const textLength = node.textContent.length;

                const results = deidResponse?.deIdentifyResults ?? [];

                results.forEach(({ start, end, label }) => {
                    if (start >= blockOffset && end <= textLength + blockOffset) {
                        transaction.addMark(
                            start + position - blockOffset + 1,
                            end + position - blockOffset + 1,
                            schema.mark('redaction', {
                                'data-label': label,
                            })
                        );
                    }
                });
                return false;
            }
            return true;
        });

        return editorViewRef.current.dispatch(transaction);
    }, [deidResponse, blockTextOffset]);

    const clearEditor = useCallback(() => {
        if (!editorViewRef.current) return;

        clearDeidResponse(storeOptions.store);

        const tr = editorViewRef.current.state.tr;
        tr?.setSelection(new AllSelection(editorViewRef.current.state.doc));
        tr?.deleteSelection();
        editorViewRef.current.dispatch(tr);

        editorViewRef.current?.focus();
    }, [storeOptions.store]);

    const setEditorContent = useCallback(
        (content: string) => {
            const editorView = editorViewRef.current;

            if (!editorView) return;

            const tr = editorViewRef.current?.state.tr;

            if (!tr) return;

            tr.setSelection(new AllSelection(editorView.state.doc));
            tr.replaceSelectionWith(htmlStringToProseMirrorNode(content));
            editorView.dispatch(tr);

            clearDeidResponse(storeOptions.store);
        },
        [storeOptions.store]
    );

    const editorClassName = classNames(styles.editor, {
        [styles.staleMarks]: responseStaleOrMissing,
        [styles.freshMarks]: !responseStaleOrMissing,
    });

    return (
        <QuiBox display="flex" flexDirection="column" gap="sm">
            <Controls setEditorContent={setEditorContent} showClearButton={blockTextContent.join('').length > 0} clearEditor={clearEditor} />

            <div className={styles.app}>
                <div className={styles.editorColumn}>
                    <div className={styles.editorBackdrop} />

                    {blockTextContent.join('').length === 0 && (
                        <div className={styles.placeholder}>
                            <QuiBox text="mono-text-lg" color="text-base--disabled" padding="lg">
                                Start by typing here...
                            </QuiBox>
                        </div>
                    )}

                    <div className={editorClassName} ref={containerRef} />
                </div>

                <Preview />
            </div>
        </QuiBox>
    );
}
