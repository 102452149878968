import { PiiTypeEnum } from '@/types';
import { QuiButton, QuiSegmentedControlButton, QuiSegmentedControlField } from '@tonicai/ui-quinine';
import { useMemo, useState } from 'react';
import { useField } from 'react-final-form';
import { GeneratorMetadataProps } from '../../types';
import { DateTimeGeneratorMetadataField } from './DateTimeGeneratorMetadataField';
import { LocationGeneratorMetadataField } from './LocationGeneratorMetadataField';
import { NameGeneratorMetadataField } from './NameGeneratorMetadataField';

export const piiTypeMetadataComponents: Partial<Record<PiiTypeEnum, React.FC<GeneratorMetadataProps>>> = {
    [PiiTypeEnum.DATE_TIME]: DateTimeGeneratorMetadataField,
    [PiiTypeEnum.LOCATION]: LocationGeneratorMetadataField,
    [PiiTypeEnum.LOCATION_ADDRESS]: LocationGeneratorMetadataField,
    [PiiTypeEnum.LOCATION_ZIP]: LocationGeneratorMetadataField,
    [PiiTypeEnum.LOCATION_STATE]: LocationGeneratorMetadataField,
    [PiiTypeEnum.LOCATION_CITY]: LocationGeneratorMetadataField,
    [PiiTypeEnum.PERSON]: NameGeneratorMetadataField,
    [PiiTypeEnum.NAME_GIVEN]: NameGeneratorMetadataField,
    [PiiTypeEnum.NAME_FAMILY]: NameGeneratorMetadataField,
};
type Props = {
    piiType: PiiTypeEnum;
    automaticallySubmit?: boolean;
    disabled?: boolean;
};
export default function useEntityOptions({ piiType, automaticallySubmit = true, disabled = false }: Props) {
    const MetadataComponent = piiType in piiTypeMetadataComponents ? piiTypeMetadataComponents[piiType] : null;
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const {
        input: { value: generatorSetupValue },
    } = useField(`generatorSetup.${piiType}`);

    return useMemo(
        () => ({
            EntityOptionButtons() {
                return (
                    <>
                        {MetadataComponent && generatorSetupValue === 'Synthesis' && (
                            <QuiButton
                                onClick={() => setShowOptions(!showOptions)}
                                variant={showOptions ? 'primary' : 'outline-default'}
                                size={'sm'}
                                type={'button'}
                                iconRight={showOptions ? 'chevron-up' : 'chevron-down'}
                            >
                                Options
                            </QuiButton>
                        )}
                        <QuiSegmentedControlField size="sm" name={`generatorSetup.${piiType}`}>
                            <QuiSegmentedControlButton disabled={disabled} value="Synthesis">
                                Synthesis
                            </QuiSegmentedControlButton>
                            <QuiSegmentedControlButton disabled={disabled} value="Redaction">
                                Redaction
                            </QuiSegmentedControlButton>
                            <QuiSegmentedControlButton disabled={disabled} value="Off">
                                Off
                            </QuiSegmentedControlButton>
                        </QuiSegmentedControlField>
                    </>
                );
            },

            EntityOptionMetadata() {
                return showOptions && generatorSetupValue == 'Synthesis' && MetadataComponent ? (
                    <MetadataComponent piiType={piiType} name={`datasetGeneratorMetadata.${piiType}`} automaticallySubmit={automaticallySubmit} />
                ) : null;
            },
        }),
        [piiType, MetadataComponent, generatorSetupValue, showOptions, automaticallySubmit, disabled]
    );
}
