import { EndpointGuard } from '../EndpointGuard/EndpointGuard';
import { getBlob } from '../../utils';
import { useEffect, useMemo, useState } from 'react';
import { useScale } from '../Templates/useScale';
import { Message } from '../Message/Message';
import { QuiBox, QuiSpinner } from '@tonicai/ui-quinine';
import { ZoomButtons } from '../ZoomButtons/ZoomButtons';

type PipelineSynthesizedImagePreviewContentProps = Readonly<{
    blobString: string;
}>;

function PipelineSynthesizedImagePreviewContent({ blobString }: PipelineSynthesizedImagePreviewContentProps) {
    const blob = useMemo(() => getBlob(blobString), [blobString]);
    const scale = useScale();
    const [dimensions, setDimensions] = useState<{
        width: number;
        height: number;
    } | null>(null);
    const [status, setStatus] = useState<'loading' | 'loaded' | 'error'>('loading');

    useEffect(() => {
        setStatus('loading');
        setDimensions(null);

        const img = new Image();

        const abortController = new AbortController();

        img.addEventListener(
            'load',
            () => {
                setDimensions({
                    width: img.width,
                    height: img.height,
                });
                setStatus('loaded');
            },
            {
                signal: abortController.signal,
            }
        );

        img.addEventListener('error', () => setStatus('error'), {
            signal: abortController.signal,
        });

        img.src = blob;

        return () => {
            abortController.abort();
        };
    }, [blob]);

    if (status === 'error' || (status === 'loaded' && !dimensions)) {
        return <Message variant="error">Error loading image</Message>;
    }

    if (status === 'loading') {
        return (
            <Message variant="info">
                <QuiBox alignItems="center" gap="sm" display="flex">
                    <QuiSpinner size="sm" /> <span>Loading</span>
                </QuiBox>
            </Message>
        );
    }

    if (!dimensions) {
        return null;
    }

    return (
        <QuiBox padding="md">
            <QuiBox padding="none none md none">
                <QuiBox display="inline-block">
                    <ZoomButtons {...scale} />
                </QuiBox>
            </QuiBox>
            <QuiBox border="stroke-base">
                <div
                    style={{
                        maxWidth: '100%',
                        overflow: 'auto',
                        maxHeight: '100vh',
                    }}
                >
                    <img
                        alt=""
                        src={blob}
                        width={dimensions.width}
                        height={dimensions.height}
                        style={{
                            display: 'block',
                            width: `${dimensions.width * scale.scale}px`,
                            height: `${dimensions.height * scale.scale}px`,
                        }}
                    />
                </div>
            </QuiBox>
        </QuiBox>
    );
}

type PipelineSynthesizedImagePreviewProps = Readonly<{
    fileBlobUrl: string;
}>;

export function PipelineSynthesizedImagePreview({ fileBlobUrl }: PipelineSynthesizedImagePreviewProps) {
    return (
        <EndpointGuard<string>
            endpoint={fileBlobUrl}
            notFoundMessage="File not found"
            errorMessage="Error loading file preview"
            render={({ data: blobString }) => {
                return <PipelineSynthesizedImagePreviewContent blobString={blobString} />;
            }}
        />
    );
}
