// Parsed on April 22, 2024 from https://docs.aws.amazon.com/general/latest/gr/s3.html
export const AWS_S3_REGIONS = [
    'us-east-2',
    'us-east-1',
    'us-west-1',
    'us-west-2',
    'af-south-1',
    'ap-east-1',
    'ap-south-2',
    'ap-southeast-3',
    'ap-southeast-4',
    'ap-south-1',
    'ap-northeast-3',
    'ap-northeast-2',
    'ap-southeast-1',
    'ap-southeast-2',
    'ap-northeast-1',
    'ca-central-1',
    'ca-west-1',
    'eu-central-1',
    'eu-west-1',
    'eu-west-2',
    'eu-south-1',
    'eu-west-3',
    'eu-south-2',
    'eu-north-1',
    'eu-central-2',
    'il-central-1',
    'me-south-1',
    'me-central-1',
    'sa-east-1',
    'us-gov-east-1',
    'us-gov-west-1',
] as const;

export type AWSS3Region = (typeof AWS_S3_REGIONS)[number];

export const AWS_S3_REGIONS_OPTIONS: {
    label: AWSS3Region;
    value: AWSS3Region;
}[] = AWS_S3_REGIONS.map((region) => ({
    label: region,
    value: region,
})).sort((a, b) => a.label.localeCompare(b.label));
