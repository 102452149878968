import { QuiBox, QuiButton, QuiCell, QuiFlexBoxColumn, QuiGridLayout, QuiIcon, QuiLink, QuiSpinner, useQuiModal } from '@tonicai/ui-quinine';
import { useEffect } from 'react';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { fetchParseJobs, parseJobsAtom, parseJobsStatusAtom } from '../../stores/parse-jobs';
import { useAtomValue } from 'jotai';
import { CreateFirstParseJob } from './CreateFirstParseJob';
import { StartChatButton } from '../../components/StartChatButton/StartChatButton';
import { PIPELINES_DESCRIPTION } from '../../constants';
import { CreatePipelineDialog } from '../../components/CreatePipelineDialog/CreatePipelineDialog';
import { PipelinesDatasetsTable } from '@/components/PipelinesDatasetsTable/PipelinesDatasetsTable';

export function ParseJobs() {
    const parseJobs = useAtomValue(parseJobsAtom);
    const parseJobsStatus = useAtomValue(parseJobsStatusAtom);
    const createParseJobDialog = useQuiModal();

    useEffect(() => {
        fetchParseJobs();
    }, []);

    return (
        <QuiBox maxWidth="wide" padding="md">
            <QuiGridLayout gap="md">
                <QuiCell width={3}>
                    <QuiBox display="flex" alignItems="start" flexDirection="column" gap="md" padding="none lg none none">
                        <PageTitle icon="git-branch" title="Pipelines" />
                        <QuiBox text="text-md" color="text-subdued">
                            {PIPELINES_DESCRIPTION}
                        </QuiBox>
                        <QuiButton data-test="create-new-pipeline-button" type="button" variant="brand-purple" onClick={createParseJobDialog.open}>
                            Create a New Pipeline
                        </QuiButton>
                    </QuiBox>
                </QuiCell>
                <QuiCell width={6}>
                    {parseJobsStatus === 'success' && Array.isArray(parseJobs) && parseJobs?.length === 0 ? (
                        <CreateFirstParseJob openDialog={createParseJobDialog.open} />
                    ) : null}

                    {Array.isArray(parseJobs) ? <PipelinesDatasetsTable excludeDatasets /> : null}

                    {parseJobsStatus === 'loading' || parseJobsStatus === 'init' ? (
                        <QuiBox padding="md">
                            <QuiSpinner />
                        </QuiBox>
                    ) : null}
                </QuiCell>
                <QuiCell width={3}>
                    <QuiFlexBoxColumn gap="md">
                        <QuiFlexBoxColumn bg="background-base" gap="sm" borderRadius="md" padding="md" border="stroke-base">
                            <QuiBox weight="medium" text="text-sm">
                                Sensitive words
                            </QuiBox>
                            <QuiBox weight="bold" text="text-xl">
                                0
                            </QuiBox>
                            <QuiBox color="text-subdued" text="text-xs">
                                0% of parsed data
                            </QuiBox>
                        </QuiFlexBoxColumn>

                        <QuiFlexBoxColumn bg="background-base" gap="sm" borderRadius="md" padding="md" border="stroke-base">
                            <QuiBox display="flex" gap="sm">
                                <QuiBox>
                                    <QuiIcon icon="life-buoy" />
                                </QuiBox>
                                <QuiFlexBoxColumn>
                                    <QuiBox weight="medium" text="text-sm">
                                        Getting started
                                    </QuiBox>
                                    <QuiBox text="text-xs" color="text-subdued">
                                        Checkout our product documentation.
                                    </QuiBox>

                                    <div>
                                        <QuiLink
                                            variant="currentcolor"
                                            iconRight="external-link"
                                            size="text-sm"
                                            external
                                            to="https://docs.tonic.ai/textual"
                                        >
                                            View documentation
                                        </QuiLink>
                                        &nbsp;
                                        <StartChatButton />
                                    </div>
                                </QuiFlexBoxColumn>
                            </QuiBox>
                        </QuiFlexBoxColumn>
                    </QuiFlexBoxColumn>
                </QuiCell>
            </QuiGridLayout>
            <CreatePipelineDialog isOpen={createParseJobDialog.isOpen} onClose={createParseJobDialog.close} />
        </QuiBox>
    );
}
