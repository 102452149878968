import { QuiText, QuiTooltip } from '@tonicai/ui-quinine';
import { useEffect, useRef, useState } from 'react';

type SmartToolTipProps = {
    text: string;
};

export function SmartToolTip({ text }: SmartToolTipProps) {
    //so we only show tooltip on regex when necessary
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const checkOverflow = () => {
            if (textRef.current) {
                const { scrollWidth, clientWidth } = textRef.current;
                setIsOverflowing(scrollWidth > clientWidth);
            }
        };

        // Run on mount and on resize
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, []);

    if (isOverflowing) {
        return (
            <QuiTooltip content={text}>
                <QuiText ref={textRef} ellipsisOverflow={true} size={'text-sm'}>
                    {text}
                </QuiText>
            </QuiTooltip>
        );
    }
    return (
        <QuiText ref={textRef} ellipsisOverflow={true} size={'text-sm'}>
            {text}
        </QuiText>
    );
}
