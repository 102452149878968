import { Field, FormSpy } from 'react-final-form';
import { QuiBox, QuiForm, QuiPasswordField, QuiSubmitButton, QuiText, QuiTextField } from '@tonicai/ui-quinine';
import { ResetPasswordRequestModel } from '../../types';
import { resetPassword } from '../../stores/auth';
import { Message } from '../../components/Message/Message';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { Auth } from '../../components/Auth/Auth';
import { FORM_ERROR } from 'final-form';

export function ResetPassword() {
    const [searchParams] = useSearchParams();

    const token = searchParams.get('token');

    if (!token) return <Navigate to="/signin" />;

    return (
        <Auth>
            <QuiForm<ResetPasswordRequestModel>
                initialvalues={{ token }}
                onSubmit={(values: ResetPasswordRequestModel) => {
                    return resetPassword({
                        ...values,
                        token,
                    })
                        .then(() => {
                            return undefined;
                        })
                        .catch(() => {
                            return { [FORM_ERROR]: 'failure' };
                        });
                }}
            >
                <QuiBox display="flex" flexDirection="column" gap="md">
                    <QuiText size="display-xs" weight="medium">
                        Reset Your Password
                    </QuiText>
                    <Field type="hidden" component="input" name="token" />
                    <QuiTextField name="userName" label="Email Address" />
                    <QuiPasswordField name="newPassword" label="New Password" />
                    <QuiSubmitButton variant="primary">Reset Password</QuiSubmitButton>
                    <FormSpy subscription={{ submitSucceeded: true }}>
                        {(props: { submitSucceeded: boolean }) =>
                            props.submitSucceeded ? (
                                <Message variant="success">
                                    Password reset successfully. You can now <Link to="/signin">log in.</Link>
                                </Message>
                            ) : null
                        }
                    </FormSpy>
                    <FormSpy subscription={{ submitError: true }}>
                        {(props: { submitError: boolean }) =>
                            props.submitError ? (
                                <Message variant="error">Unable to reset password. Reset password links are only valid for 24 hours.</Message>
                            ) : null
                        }
                    </FormSpy>
                </QuiBox>
            </QuiForm>
        </Auth>
    );
}
