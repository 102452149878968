import { GeneratorMetadataProps, ModelTransformation } from '@/types';
import { QuiBox, QuiCheckboxField, QuiNumberField, QuiSelectField } from '@tonicai/ui-quinine';
import { useEffect } from 'react';
import { useField, useForm, useFormState } from 'react-final-form';
import { AdditionalDateFormatField } from './AdditionalDateFormatField';

export function parseNumber(inputValue?: string) {
    const validCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    if (typeof inputValue !== 'string') return 0;

    const justNumbers =
        inputValue
            .split('')
            .filter((char) => validCharacters.includes(char))
            .join('') ?? '0';

    const parsed = parseInt(justNumbers, 10);
    if (isNaN(parsed)) {
        return 0;
    } else {
        return parsed;
    }
}

export function formatNumber(inputValue?: number) {
    if (typeof inputValue === 'number') return inputValue.toString();
    return '0';
}

export const numberConfig = {
    parse: parseNumber,
    format: formatNumber,
};

export function DateTimeGeneratorMetadataField({ name, automaticallySubmit }: GeneratorMetadataProps) {
    const form = useForm();

    const scrambleUnrecognizedDates = useField(`${name}.scrambleUnrecognizedDates`, { subscription: { value: true } });

    const formState = useFormState({ subscription: { submitting: true } });

    useEffect(() => {
        if (automaticallySubmit) {
            form.submit();
        }
    }, [scrambleUnrecognizedDates.input.value, form, automaticallySubmit]);

    return (
        <QuiBox display="flex" flexDirection="column" gap="sm">
            <QuiBox borderRadius="md" border="stroke-base" text="text-xs">
                <QuiBox display="flex" flexDirection="column" borderRadius="md" padding={'md'} gap={'md'}>
                    <QuiSelectField
                        onBlur={() => {
                            if (automaticallySubmit) {
                                form.submit();
                            }
                        }}
                        isClearable={false}
                        name={`${name}.dateTimeTransformation`}
                        label="Transformation"
                        placeholder=""
                        options={[
                            {
                                value: ModelTransformation.TimestampShift,
                                label: 'Timestamp Shift',
                            },
                        ]}
                    />
                    <QuiNumberField
                        config={numberConfig}
                        onBlur={() => {
                            if (automaticallySubmit) {
                                form.submit();
                            }
                        }}
                        label="# of Days To shift +/-"
                        name={`${name}.metadata.timestampShiftInDays`}
                    />
                    <QuiCheckboxField
                        name={`${name}.scrambleUnrecognizedDates`}
                        label={'Scramble Unrecognized Dates'}
                        helperText={
                            'When enabled, dates are randomly scrambled if they are not parseable.  Otherwise, they will just be passed through'
                        }
                        disabled={formState.submitting}
                    />
                    <AdditionalDateFormatField automaticallySubmit={automaticallySubmit} name={`${name}.additionalDateFormats`} />
                </QuiBox>
            </QuiBox>
        </QuiBox>
    );
}
