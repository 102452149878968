import { CopyToClipboardButton } from '../CopyToClipboardButton/CopyToClipboardButton';
import {
    QuiBox,
    QuiButton,
    QuiIconEnum,
    QuiModalContent,
    QuiModalDialog,
    QuiModalFooter,
    QuiSpinner,
    QuiText,
    useQuiModal,
} from '@tonicai/ui-quinine';
import { CodeBlock, CodeBlockProps } from './CodeBlock';

export type CodeBoxProps = Readonly<
    {
        title: string;
        loading?: boolean;
        disableCopy?: boolean;
        expandable?: boolean;
    } & CodeBlockProps
>;

export function CodeBox({ title, loading, disableCopy, expandable, maxHeight, ...codeBlockProps }: CodeBoxProps) {
    const modal = useQuiModal();
    return (
        <>
            <QuiBox bg="background-base" border="stroke-base" borderRadius="md">
                <QuiBox borderBottom="stroke-base" padding="sm" text="mono-text-xs" display={'flex'} justifyContent={'space-between'}>
                    <QuiBox display="flex" gap="sm" alignItems="center">
                        {loading ? <QuiSpinner size="xs" /> : null}
                        {title}
                    </QuiBox>
                    <QuiBox gap="sm" display="flex">
                        {expandable && (
                            <QuiButton variant="minimal" iconLeft={QuiIconEnum.Maximize2} size="xs" onClick={modal.open}>
                                View
                            </QuiButton>
                        )}
                        {disableCopy !== true ? (
                            <CopyToClipboardButton size="xs" variant="minimal" icon="copy" content={codeBlockProps.code}>
                                Copy
                            </CopyToClipboardButton>
                        ) : null}
                    </QuiBox>
                </QuiBox>
                <CodeBlock maxHeight={maxHeight} {...codeBlockProps} />
            </QuiBox>

            <QuiModalDialog
                disableDismissOnEscapeKeyDown={false}
                isOpen={modal.isOpen}
                onClose={modal.close}
                title={<QuiText size="text-lg">{title}</QuiText>}
            >
                <QuiModalContent>
                    <QuiBox display={'flex'} flexDirection={'column'} gap={'md'}>
                        <CodeBlock {...codeBlockProps} />
                    </QuiBox>
                </QuiModalContent>

                <QuiModalFooter>
                    <QuiBox display={'flex'} justifyContent={'end'} className={'qui-w-100'} gap={'sm'}>
                        <QuiButton type="button" onClick={modal.close}>
                            Close
                        </QuiButton>
                        <CopyToClipboardButton variant="brand-purple" includeIcon={false} content={codeBlockProps.code}>
                            Copy
                        </CopyToClipboardButton>
                    </QuiBox>
                </QuiModalFooter>
            </QuiModalDialog>
        </>
    );
}
