import { FileDetails } from './FileDetails';
import { FileIcon } from '../FileIcon/FileIcon';
import { getFileNameFromUrl } from '../../utils';
import { QuiBox, QuiButton } from '@tonicai/ui-quinine';
import { FileAdderFileItemMenu } from './FileAdderFileItemMenu';
import { useDatasetFile } from '../../stores/datasetFiles';
import { useFilePreviewContext } from '../FilePreviews/useFilePreviewContext';

type FileAdderFileRowProps = Readonly<{
    fileId: string;
    datasetId: string;
}>;

export function FileAdderFileItem({ fileId, datasetId }: FileAdderFileRowProps) {
    const file = useDatasetFile(fileId);
    const { openFilePreview } = useFilePreviewContext();

    if (!file) return null;

    return (
        <QuiBox
            bg="background-base"
            borderTop="stroke-base"
            padding="sm sm sm sm"
            display={'flex'}
            flexDirection={'row'}
            gap={'xs'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <QuiButton
                style={{
                    border: 'none',
                    padding: '0px .5em 0px .5em',
                    minWidth: '50px',
                    justifyContent: 'start',
                    textAlign: 'left',
                }}
                onClick={() => openFilePreview(file.fileId)}
            >
                <QuiBox display="flex" flexGrow="1" alignItems="center" gap="sm" overflow="hidden">
                    <QuiBox display="flex" flexDirection="column" flexGrow="1" overflow="hidden">
                        <QuiBox text="text-xs" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                            {file.fileSource == 'Local' ? file.fileName : `${getFileNameFromUrl(file.fileName)} (${file.fileSource})`}
                        </QuiBox>
                        <QuiBox
                            color="text-base"
                            text="text-xs"
                            display="flex"
                            gap="xs"
                            alignItems="center"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            overflow="hidden"
                        >
                            <FileIcon jobStatus={file.processingStatus} />

                            <FileDetails
                                jobStatus={file.processingStatus}
                                processingError={file.processingError}
                                numRows={file.numRows}
                                wordCount={file.wordCount}
                            />
                        </QuiBox>
                    </QuiBox>
                </QuiBox>
            </QuiButton>
            <QuiBox display="flex" gap="sm" flexShrink="0" alignItems="center">
                <FileAdderFileItemMenu datasetId={datasetId} file={file} />
            </QuiBox>
        </QuiBox>
    );
}
