import { Navigate, useParams } from 'react-router-dom';
import { QuiBox, QuiFlexBoxColumn, QuiSpinner } from '@tonicai/ui-quinine';
import { useEndpoint } from '../../hooks/useEndpoint';
import { FileParseJobResponse, FilesParsedResponse, JobStatus } from '../../types';
import { Message } from '../../components/Message/Message';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { JobStatusTag } from '../../components/JobStatusTag/JobStatusTag';
import { EndpointGuard } from '../../components/EndpointGuard/EndpointGuard';
import { FilesTable } from './FilesTable';
import { CancelFileParseJobButton } from '../../components/CancelFileParseJobButton/CancelFileParseJobButton';
import { useEffect } from 'react';
import { CopyToClipboardButton } from '../../components/CopyToClipboardButton/CopyToClipboardButton';
import { FileParseJobContext } from './FileParseJobContext';
import { usePipelineContext } from '../../components/PipelineLayout/usePipelineContext';

export function FileParseJob() {
    const params = useParams<{ fileParseJobId: string }>();
    const fileParseJobId = params.fileParseJobId!;
    const { parseJobConfig, parseJobConfigId } = usePipelineContext();
    const {
        data: fileParseJob,
        fetchStatus: fileParseJobStatus,
        fetchData: fetchFileParseJob,
    } = useEndpoint<FileParseJobResponse>(`/api/parsejob/${fileParseJobId}/job`);

    // Refetch the file parse job every 3 seconds if it's still running
    useEffect(() => {
        if (fileParseJob?.status !== JobStatus.QUEUED && fileParseJob?.status !== JobStatus.RUNNING) return;

        const timeout = window.setTimeout(() => {
            fetchFileParseJob();
        }, 3000);

        return () => {
            window.clearTimeout(timeout);
        };
    }, [fileParseJob, fetchFileParseJob]);

    if (parseJobConfig.useInternalBucket) {
        return <Navigate to={`/pipelines/${parseJobConfig.id}`} />;
    }

    if (fileParseJobStatus === 'loading' || fileParseJobStatus === 'init') {
        return <QuiSpinner />;
    }

    if (fileParseJobStatus === 'error' || (fileParseJobStatus === 'success' && !fileParseJob)) {
        return <Message variant="error">Error loading pipeline run</Message>;
    }

    if (!fileParseJob) {
        return <Message variant="error">Pipeline run not found</Message>;
    }

    const showCancel = fileParseJob.status === JobStatus.RUNNING || fileParseJob.status === JobStatus.QUEUED;

    return (
        <QuiFlexBoxColumn gap="md" padding="md">
            <QuiFlexBoxColumn gap="sm">
                <QuiBox display="flex" justifyContent="space-between">
                    <PageTitle
                        icon="activity"
                        title={
                            <QuiFlexBoxColumn>
                                Pipeline Run
                                <QuiBox display="flex" alignItems="center" gap="sm" text="text-xs" color="text-base--disabled">
                                    {fileParseJob.id} <CopyToClipboardButton variant="minimal" size="xs" content={fileParseJob.id} />
                                </QuiBox>
                            </QuiFlexBoxColumn>
                        }
                    />

                    <QuiBox display="flex" gap="md" alignItems="center">
                        <JobStatusTag status={fileParseJob.status} />

                        {showCancel ? <CancelFileParseJobButton fileParseJobId={fileParseJobId} parseJobConfigId={parseJobConfigId} /> : null}
                    </QuiBox>
                </QuiBox>
            </QuiFlexBoxColumn>

            {fileParseJob.status === JobStatus.RUNNING ? <Message variant="info">Files are still being processed</Message> : null}

            <FileParseJobContext.Provider value={fileParseJob}>
                <EndpointGuard<FilesParsedResponse>
                    endpoint={`/api/parsejob/${fileParseJobId}/files`}
                    errorMessage="Error loading files"
                    notFoundMessage="No files found"
                    Component={FilesTable}
                />
            </FileParseJobContext.Provider>
        </QuiFlexBoxColumn>
    );
}
