import { QuiBox, QuiButton, QuiFlexBoxColumn, QuiModalContent, QuiModalDialog } from '@tonicai/ui-quinine';
import { SubscriptionBadge } from './SubscriptionBadge';
import { CloudSubscriptionStatus, PRICE_PER_1000_WORDS } from '@/types';
import styles from './PlanDialog.module.scss';
import { useBillingStatus } from '@/stores';
import { SelectPayAsYouGoPlan } from './SelectPayAsYouGoPlan';

type PlanDialogProps = Readonly<{
    isOpen: boolean;
    onClose: () => void;
}>;

export function PlanDialog({ isOpen, onClose }: PlanDialogProps) {
    const status = useBillingStatus();

    const handleTalkToSales = () => {
        onClose();
        if (window?.Intercom) {
            window.Intercom('showNewMessage', "Hi, I'd like to learn more about volume discounts.");
        }
    };

    return (
        <QuiModalDialog transitionDuration={0} isOpen={isOpen} onClose={onClose}>
            <QuiModalContent>
                <QuiFlexBoxColumn className={styles.content} gap="lg">
                    <QuiBox padding="md none none none">
                        <QuiBox text="text-lg" weight="medium">
                            Select your plan
                        </QuiBox>
                    </QuiBox>
                    <div className={styles.columns}>
                        <div>
                            <QuiBox
                                border={status === CloudSubscriptionStatus.None ? 'stroke-positive' : 'stroke-base'}
                                borderRadius="md"
                                padding="lg"
                            >
                                <QuiFlexBoxColumn gap="md">
                                    <QuiBox>
                                        <SubscriptionBadge status={CloudSubscriptionStatus.None} />
                                    </QuiBox>
                                    <QuiBox weight="medium">Limited to 100K words</QuiBox>
                                    <QuiBox color="text-subdued">
                                        Scan up to 100,000 words with the Free Trial plan. Upgrade your plan once your limit is reached.
                                    </QuiBox>
                                    <QuiBox>
                                        <QuiButton disabled variant="minimal">
                                            Current Plan
                                        </QuiButton>
                                    </QuiBox>
                                </QuiFlexBoxColumn>
                            </QuiBox>
                        </div>
                        <div>
                            <QuiBox
                                border={status === CloudSubscriptionStatus.Active ? 'stroke-positive' : 'stroke-base'}
                                borderRadius="md"
                                padding="lg"
                            >
                                <QuiFlexBoxColumn gap="md">
                                    <QuiBox>
                                        <SubscriptionBadge status={CloudSubscriptionStatus.Active} />
                                    </QuiBox>
                                    <QuiBox weight="medium">Unlock unlimited words</QuiBox>
                                    <QuiBox color="text-secondary">
                                        {`Upgrade your plan to continue using Textual without limits. Plans start at $${PRICE_PER_1000_WORDS.toFixed(2)} per 1,000 words.`}
                                    </QuiBox>
                                    <QuiBox>
                                        <SelectPayAsYouGoPlan />
                                    </QuiBox>
                                </QuiFlexBoxColumn>
                            </QuiBox>
                        </div>
                        <div>
                            <QuiBox
                                border={status === CloudSubscriptionStatus.Active ? 'stroke-positive' : 'stroke-base'}
                                borderRadius="md"
                                padding="lg"
                            >
                                <QuiFlexBoxColumn gap="md">
                                    <QuiBox>
                                        <QuiBox weight="medium" text="text-lg">
                                            High Volume Plans
                                        </QuiBox>
                                    </QuiBox>
                                    <QuiBox weight="medium">Unlock discounts</QuiBox>
                                    <QuiBox color="text-secondary">
                                        Have a lot of words to scan? Reach out to our sales team to unlock steep discounts on high volume plans.
                                    </QuiBox>
                                    <QuiBox>
                                        <QuiButton variant="positive" onClick={handleTalkToSales} iconRight={'message-circle'}>
                                            Chat with sales
                                        </QuiButton>
                                    </QuiBox>
                                </QuiFlexBoxColumn>
                            </QuiBox>
                        </div>
                    </div>
                    <QuiBox margin="md none">
                        <QuiBox display="flex" justifyContent="space-between" alignItems="center">
                            <QuiButton type="button" onClick={onClose}>
                                Cancel
                            </QuiButton>
                        </QuiBox>
                    </QuiBox>
                </QuiFlexBoxColumn>
            </QuiModalContent>
        </QuiModalDialog>
    );
}
