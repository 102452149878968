import { FileSourceEnum, ParseJobConfigRequest, ObjectStorageType, AwsCredentialSourceEnum } from '../types';

export const TEXTUAL_DOCUMENTATION_URL = 'https://docs.tonic.ai/textual/';
export const TEXTUAL_PRICING_URL = 'https://tonic.ai/pricing';
export const SDK_DOCUMENTATION_URL = 'https://tonic-ai-textual-sdk.readthedocs-hosted.com/en/latest/';

export const NEW_PARSE_JOB_CONFIG_REQUEST: ParseJobConfigRequest = {
    name: '',
    objectStorageType: ObjectStorageType.S3,
    fileSource: FileSourceEnum.Aws,
    parseJobExternalCredential: {
        fileSource: FileSourceEnum.Aws,
        credential: {
            accessKey: '',
            secretKey: '',
            region: '',
        },
    },
    selectedFiles: [],
    pathPrefixes: [],
    selectedFileExtensions: [],
    outputPath: '',
    synthesizeFiles: false,
    awsCredentialSource: AwsCredentialSourceEnum.UserProvided,
};

export const PIPELINES_DESCRIPTION = 'Standardize unstructured data to prepare it for ingestion into your RAG or LLM system.';
export const DATASETS_DESCRIPTION = 'Take files with sensitive information and create redacted or synthesized versions.';
export const PREVIEW_HIGHLIGHT_COLOR = 'rgba(255, 223, 107, 0.5)';
