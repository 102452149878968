import { useAtomValue } from 'jotai';
import { Auth } from '../../components/Auth/Auth';
import { SSOButtons } from '../../components/Auth/SSOButtons';
import { SignUpForm } from '../../components/Auth/SignUpForm';
import { QuiBox, QuiText } from '@tonicai/ui-quinine';
import { Link, Navigate } from 'react-router-dom';
import { isSsoRequiredAtom, signedInAtom } from '../../stores/auth';
import useIsMobile from '@/hooks/useIsMobile';

export function SignUp() {
    const signedIn = useAtomValue(signedInAtom);
    const isSsoRequired = useAtomValue(isSsoRequiredAtom);
    const isMobile = useIsMobile();

    if (signedIn) {
        return <Navigate to="/" />;
    }

    return (
        <QuiBox display="flex" justifyContent="center" style={{ height: '100vh' }}>
            <QuiBox style={{ width: '510px', zIndex: 1, backgroundColor: 'white' }}>
                <Auth hideBorder={true}>
                    <QuiBox display={'flex'} flexDirection={'column'} gap={'lg'}>
                        <QuiText data-test="sign-up-page-title" size="display-xs" weight="bold">
                            Create account
                        </QuiText>
                        {/*<QuiText text="text-sm" color={'text-secondary'}>
                            An email with your activation link for your free trial account will be sent to the address provided below.
                        </QuiText>*/}
                        <SSOButtons prefix="Create account using" />
                        {!isSsoRequired && <SignUpForm />}
                        <QuiText as="p">
                            Already have an account? <Link to="/signin">Log In</Link>
                        </QuiText>
                    </QuiBox>
                </Auth>
            </QuiBox>
            {!isMobile && (
                <div
                    style={{
                        background: 'black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        flexGrow: 1,
                        overflow: 'hidden',
                    }}
                >
                    <img src="/assets/images/signup-banner.png" />
                    <QuiBox display={'flex'} flexDirection={'column'} gap="sm" style={{ width: '75%' }}>
                        <QuiText size={'display-lg'} style={{ textAlign: 'center', color: 'white' }}>
                            Unlock your free-text data for AI
                        </QuiText>
                        <QuiText size={'display-xs'} style={{ textAlign: 'center', color: 'white' }}>
                            Safely leverage your unstructured data for AI development through industry-leading free-text redaction and synthesis.
                        </QuiText>
                    </QuiBox>
                </div>
            )}
        </QuiBox>
    );
}
