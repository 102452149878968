import { Link, Navigate } from 'react-router-dom';
import { Auth } from '../../components/Auth/Auth';
import { SSOButtons } from '../../components/Auth/SSOButtons';
import { SignInForm } from '../../components/Auth/SignInForm';
import { QuiBox, QuiText } from '@tonicai/ui-quinine';
import { useAtomValue } from 'jotai';
import { isSsoRequiredAtom, signedInAtom } from '../../stores/auth';

export function SignIn() {
    const signedIn = useAtomValue(signedInAtom);
    const isSsoRequired = useAtomValue(isSsoRequiredAtom);

    if (signedIn) {
        return <Navigate to="/" />;
    }

    return (
        <QuiBox display="flex" justifyContent="center" margin="xl">
            <QuiBox style={{ margin: '2rem auto', maxWidth: '20rem' }}>
                <Auth>
                    <QuiText size="display-xs" weight="medium">
                        Log In
                    </QuiText>
                    <SSOButtons prefix="Log In With" />
                    {!isSsoRequired && <SignInForm />}
                    <QuiText className="qui-text-align-center" as="p">
                        Need an account?{' '}
                        <Link to="/signup" data-test="signup-form">
                            Sign Up
                        </Link>
                    </QuiText>
                </Auth>
            </QuiBox>
        </QuiBox>
    );
}
