import { PiiTypeGeneratorState } from '@/types';
import { QuiBox, QuiMenu, QuiMenuButton, QuiMenuItem, QuiMenuItems } from '@tonicai/ui-quinine';

type BulkRedactSelectProps = {
    handleSelect: (selected: PiiTypeGeneratorState) => void;
};

export function BulkRedact({ handleSelect }: BulkRedactSelectProps) {
    return (
        <QuiBox bg="background-base" style={{ width: '120px' }}>
            <QuiMenu>
                <QuiMenuButton iconRight="chevron-down">Bulk Edit</QuiMenuButton>
                <QuiMenuItems>
                    <QuiMenuItem onClick={() => handleSelect('Synthesis')}>Synthesize</QuiMenuItem>
                    <QuiMenuItem onClick={() => handleSelect('Redaction')}>Redact</QuiMenuItem>
                    <QuiMenuItem onClick={() => handleSelect('Off')}>Off</QuiMenuItem>
                </QuiMenuItems>
            </QuiMenu>
        </QuiBox>
    );
}
