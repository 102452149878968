import { PiiTypeGeneratorState } from '@/types';
import { QuiBox, QuiButton, QuiText } from '@tonicai/ui-quinine';

type GeneratorValueProps = {
    selected: PiiTypeGeneratorState;
    handleSelect: (selected: PiiTypeGeneratorState) => void;
};

export function GeneratorValue({ selected, handleSelect }: GeneratorValueProps) {
    const getVariant = (value: PiiTypeGeneratorState): 'primary' | 'minimal' => {
        if (selected === value) {
            return 'primary';
        }
        return 'minimal';
    };

    return (
        <QuiBox gap={'xs'} display={'flex'} flexDirection={'column'} borderBottom={'stroke-base'} padding={'xs'}>
            <QuiText size={'text-md'}>Generator:</QuiText>
            <QuiBox gap={'xs'} display={'flex'}>
                <QuiButton size={'sm'} onClick={() => handleSelect('Off')} variant={getVariant('Off')}>
                    Off
                </QuiButton>
                <QuiButton size={'sm'} onClick={() => handleSelect('Redaction')} variant={getVariant('Redaction')}>
                    Redact
                </QuiButton>
                <QuiButton size={'sm'} onClick={() => handleSelect('Synthesis')} variant={getVariant('Synthesis')}>
                    Synthesize
                </QuiButton>
            </QuiBox>
        </QuiBox>
    );
}
