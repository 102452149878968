import { QuiBox, QuiSpinner } from '@tonicai/ui-quinine';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BaseDocument } from '@/types';
import styles from './FileParseJobFile.module.scss';
import { nanoid } from 'nanoid';
import { RenderDeidentifyResultsToHtmlRequestMessage, RenderDeidentifyResultsToHtmlResponseMessage } from '@/workers/redactedSchema';

type RedactedProps = Readonly<{
    deidentifyResponse: BaseDocument;
}>;

export function Redacted({ deidentifyResponse }: RedactedProps) {
    const isMountedRef = useRef(false);
    const [html, setHtml] = useState<string | null>(null);
    const id = useMemo(() => nanoid(), []);
    const workerRef = useRef<Worker | null>(null);

    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        workerRef.current = new Worker(new URL('./../../workers/redactedWorker.ts', import.meta.url), {
            type: 'module',
        });

        workerRef.current.onmessage = async function messageHandler(e) {
            try {
                const result: RenderDeidentifyResultsToHtmlResponseMessage = JSON.parse(e.data);

                if (result.id !== id) return;

                if (!isMountedRef.current) return;

                setHtml(result.html);
            } catch (error) {
                console.error('error', error);
            }
        };

        return () => {
            workerRef.current?.terminate();
        };
    }, [id]);

    useEffect(() => {
        if (!workerRef.current) return;

        try {
            const message: RenderDeidentifyResultsToHtmlRequestMessage = {
                text: deidentifyResponse.content.text,
                entities: deidentifyResponse.content.entities,
                id,
                redactedTextClassName: styles.redactedText,
                redactedTextLabelClassName: styles.redactedTextLabel,
            };

            const messageString = JSON.stringify(message);

            workerRef.current.postMessage(messageString);
        } catch (e) {
            console.error(e);
        }
    }, [deidentifyResponse, id]);

    return (
        <QuiBox bg="background-base" border="stroke-base" borderRadius="md">
            <QuiBox borderBottom="stroke-base" padding="sm" text="mono-text-xs" display={'flex'} justifyContent={'space-between'}>
                <QuiBox display="flex" gap="sm">
                    Contents
                </QuiBox>
            </QuiBox>
            <QuiBox bg="background-neutral" text="mono-text-sm" padding="md">
                {html ? (
                    <div className={styles.preview} dangerouslySetInnerHTML={{ __html: html }} />
                ) : (
                    <QuiBox padding="md">
                        <QuiSpinner size="sm" />
                    </QuiBox>
                )}
            </QuiBox>
        </QuiBox>
    );
}
