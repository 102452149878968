import { PersonAgeGeneratorMetadataField } from '@/pages/Dataset/PersonAgeGeneratorMetadataField';
import { QuiBox, QuiButton, QuiIcon, QuiIconEnum, QuiPopover } from '@tonicai/ui-quinine';
import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { Dataset, DatasetFormState, GeneratorMetadataProps, PiiTypeEnum } from '@/types';
import { DateTimeGeneratorMetadataField } from './DateTimeGeneratorMetadataField';
import { LocationGeneratorMetadataField } from './LocationGeneratorMetadataField';
import { NameGeneratorMetadataField } from './NameGeneratorMetadataField';
import { RedactionTypeExamples } from './RedactionTypeExamples';
import { RegexListFieldModal } from './RegexListFieldModal';
import useEntityOptions from './useEntityOptions';
import { getPiiTypeCount, getPiiTypeExamples, getPiiTypeInfo } from './utils';

type RedactionTypeOptionsProps = Readonly<{
    piiType: PiiTypeEnum;
    dataset: Dataset;
}>;

export const piiTypeMetadataComponents: Partial<Record<PiiTypeEnum, React.FC<GeneratorMetadataProps>>> = {
    [PiiTypeEnum.DOB]: DateTimeGeneratorMetadataField,
    [PiiTypeEnum.DATE_TIME]: DateTimeGeneratorMetadataField,
    [PiiTypeEnum.PERSON_AGE]: PersonAgeGeneratorMetadataField,
    [PiiTypeEnum.LOCATION]: LocationGeneratorMetadataField,
    [PiiTypeEnum.LOCATION_ADDRESS]: LocationGeneratorMetadataField,
    [PiiTypeEnum.LOCATION_ZIP]: LocationGeneratorMetadataField,
    [PiiTypeEnum.LOCATION_STATE]: LocationGeneratorMetadataField,
    [PiiTypeEnum.LOCATION_CITY]: LocationGeneratorMetadataField,
    [PiiTypeEnum.PERSON]: NameGeneratorMetadataField,
    [PiiTypeEnum.NAME_GIVEN]: NameGeneratorMetadataField,
    [PiiTypeEnum.NAME_FAMILY]: NameGeneratorMetadataField,
};

export function RedactionTypeOptions({ piiType, dataset }: RedactionTypeOptionsProps) {
    const [isPreviewPopoverOpen, setIsPreviewPopoverOpen] = useState<boolean>(false);

    const form = useForm<DatasetFormState>();

    // There is a documented issue with stale form state when dynamically
    // altering a form's fields
    //
    // A repro of the issue is available at the codesandbox in this comment:
    // https://github.com/final-form/react-final-form/issues/984#issuecomment-1305448553
    //
    // The workaround is from here:
    // https://github.com/final-form/final-form/issues/151#issuecomment-425867172
    //
    // If this winds up introducing another bug, then we can try rendering all
    // possible fields at the first initialization and hiding the ones which
    // are empty
    useEffect(() => {
        const isNewFormField = !form.getState().values.generatorSetup[piiType];
        if (isNewFormField) {
            form.setConfig('keepDirtyOnReinitialize', false);
            form.reset();
            form.setConfig('keepDirtyOnReinitialize', true);
        }
    }, [form, piiType]);
    const { EntityOptionButtons, EntityOptionMetadata } = useEntityOptions({
        piiType,
    });

    return (
        <QuiBox key={piiType} gap="sm" flexDirection="column" display="flex" bg="background-base" border="stroke-base" borderRadius="md" padding="md">
            <QuiBox display="flex" alignItems="center" justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <QuiIcon icon={getPiiTypeInfo(piiType)?.icon ?? 'help-circle'} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className={'pii-type-label'}>{getPiiTypeInfo(piiType)?.label}</span>
                        <span className={'pii-type-description'}>{getPiiTypeInfo(piiType)?.description}</span>
                    </div>
                </div>

                <QuiBox display="flex" gap="sm" alignItems="center">
                    <QuiBox display="flex" gap="sm" alignItems="center">
                        <QuiPopover
                            isOpen={isPreviewPopoverOpen}
                            onClose={() => setIsPreviewPopoverOpen(false)}
                            hideArrow={true}
                            placement={'bottom-start'}
                            content={
                                <RedactionTypeExamples
                                    examples={getPiiTypeExamples(dataset, piiType)}
                                    piiType={piiType}
                                    totalExamples={getPiiTypeCount(dataset, piiType)}
                                />
                            }
                        >
                            <QuiButton
                                iconRight={isPreviewPopoverOpen ? QuiIconEnum.X : QuiIconEnum.Eye}
                                size={'sm'}
                                variant={isPreviewPopoverOpen ? 'outline-primary' : 'outline-default'}
                                onClick={() => (isPreviewPopoverOpen ? setIsPreviewPopoverOpen(false) : setIsPreviewPopoverOpen(true))}
                            >
                                {getPiiTypeCount(dataset, piiType)}
                            </QuiButton>
                        </QuiPopover>
                        <EntityOptionButtons />
                    </QuiBox>
                    <RegexListFieldModal initialEntityType={piiType} listType="labelAllowLists" buttonIcon={QuiIconEnum.PlusCircle} />
                    <RegexListFieldModal initialEntityType={piiType} listType="labelBlockLists" buttonIcon={QuiIconEnum.Slash} />
                </QuiBox>
            </QuiBox>
            <EntityOptionMetadata />
        </QuiBox>
    );
}
