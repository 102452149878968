import { useSearchParams } from 'react-router-dom';
import { resetForgottenPassword } from '../../stores/auth';
import { MainLayoutSlim, SlimLayoutCard } from '../../components/Layout/Layout';
import { QuiFlexV, QuiText, QuiForm } from '@tonicai/ui-quinine';
import { ResetPasswordFormBody } from './components/ResetPasswordFormBody';
import { ResetPasswordRequest } from '@/types';
import { FORM_ERROR } from 'final-form';

export function ResetPasswordPage() {
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userid');
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    const submitResetPassword = async (values: ResetPasswordRequest) => {
        try {
            await resetForgottenPassword(values);
        } catch {
            return {
                [FORM_ERROR]: 'Failed to reset password. Please request a new password reset link and try again.',
            };
        }
    };

    return (
        <MainLayoutSlim widthRem={20}>
            <SlimLayoutCard>
                <QuiFlexV gap="lg">
                    <QuiText size="display-xs" weight="medium">
                        Set Password
                    </QuiText>
                    <QuiText size={'text-sm'} weight={'normal'}>
                        Set a secure password for your account.
                    </QuiText>
                    <QuiForm<ResetPasswordRequest> onSubmit={submitResetPassword} initialValues={{ userId, token, email } as ResetPasswordRequest}>
                        <ResetPasswordFormBody />
                    </QuiForm>
                </QuiFlexV>
            </SlimLayoutCard>
        </MainLayoutSlim>
    );
}
