import { PiiTypeGeneratorState } from '@/types';
import { QuiSelect } from '@tonicai/ui-quinine';
import styles from './RedactSelect.module.scss';

type RedactOption = {
    value: PiiTypeGeneratorState;
    label: string;
};

type RedactSelectProps = {
    selected: PiiTypeGeneratorState;
    handleSelect: (selected: PiiTypeGeneratorState) => void;
};

export function RedactSelect({ selected, handleSelect }: RedactSelectProps) {
    const options: RedactOption[] = [
        { value: 'Off', label: 'Off' },
        { value: 'Redaction', label: 'Redact' },
        { value: 'Synthesis', label: 'Synthesize' },
    ];

    const getOption = (value: PiiTypeGeneratorState): RedactOption => {
        return options.find((option) => option.value === value) as RedactOption;
    };

    return (
        <QuiSelect<RedactOption>
            className={styles.redactSelect}
            // @ts-expect-error Props works, no idea why its showing error
            size={'xs'}
            value={getOption(selected)}
            options={options}
            placement={'bottom'}
            onChange={(option) => handleSelect(option!.value)}
        />
    );
}
