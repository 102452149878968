import { EditRequest } from '@/components/RequestExplorerRedactedContent/EditRequest/EditRequest';
import {
    DisplayMode,
    IdentificiationVsReplacementButtonGroup,
} from '@/components/RequestExplorerRedactedContent/IdentificationVsSynthesisButtonGroup';
import { RequestExplorerSplitView } from '@/components/RequestExplorerRedactedContent/RequestExplorerSplitView';
import { RequestExplorerUnifiedView } from '@/components/RequestExplorerRedactedContent/RequestExplorerUnifiedView';
import { resetModifyRequest, singleRequestFetchStatusAtom } from '@/stores/api_request';
import { LabelCustomList } from '@/types/api_request_record';
import { QuiBox, QuiButton, QuiIcon, QuiSpinner, QuiSwitch, QuiText } from '@tonicai/ui-quinine';
import { useEffect, useState } from 'react';
import { DetectTextEntitiesSingleModel, PiiTypeEnum, PiiTypeGeneratorState } from '@/types';
import { useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import styles from './RequestExplorerRedactedContent.module.scss';

type RedactedProps = Readonly<{
    text?: string;
    entities: DetectTextEntitiesSingleModel[];
    modifiedEntities: DetectTextEntitiesSingleModel[];
    rightOriginalPreviewHtml: string;
    rightModifiedPreviewHtml: string;
    labelBlockLists: Map<string, LabelCustomList>;
    labelAllowLists: Map<string, LabelCustomList>;
    originalGeneratorSetup: Map<PiiTypeEnum, PiiTypeGeneratorState>;
    requestId: string;
    hasModified: boolean;
}>;

export function RequestExplorerRedactedContent({
    text,
    entities,
    modifiedEntities,
    rightOriginalPreviewHtml,
    rightModifiedPreviewHtml,
    labelAllowLists,
    labelBlockLists,
    originalGeneratorSetup,
    requestId,
    hasModified,
}: RedactedProps) {
    const [showEditOption, setShowEditOption] = useState<boolean>(false);
    const [displayMode, setDisplayMode] = useState<DisplayMode>('Identification');
    const [showDiffFromOriginalToModified, setShowDiffFromOriginalToModified] = useState<boolean>(hasModified);
    const [allowList, setAllowList] = useState<Map<string, LabelCustomList>>(labelAllowLists);
    const [blockList, setBlockList] = useState<Map<string, LabelCustomList>>(labelBlockLists);
    const [generatorSetup, setGeneratorSetup] = useState<Map<PiiTypeEnum, PiiTypeGeneratorState>>(originalGeneratorSetup);

    const navigate = useNavigate();
    const loading = useAtomValue(singleRequestFetchStatusAtom);

    useEffect(() => {
        if (hasModified) {
            clearOptions();
        }
    }, [hasModified]);

    useEffect(() => {
        setAllowList(labelAllowLists);
        setBlockList(labelBlockLists);
    }, [labelAllowLists, labelBlockLists]);

    useEffect(() => {
        setGeneratorSetup(originalGeneratorSetup);
    }, [originalGeneratorSetup]);

    const getContent = () => {
        if (loading === 'loading') {
            return (
                <QuiBox display={'flex'} justifyContent={'center'}>
                    <QuiSpinner />
                </QuiBox>
            );
        }
        if (loading === 'error') {
            return (
                <QuiBox display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <QuiIcon icon={'alert-triangle'} style={{ width: 50, height: 50 }} color={'text-danger'} />
                    <QuiText size={'text-xl'} color={'text-danger'}>
                        Something went wrong. Please try again.
                    </QuiText>
                </QuiBox>
            );
        }

        if (!hasModified) {
            if (displayMode === 'Identification') {
                return <RequestExplorerUnifiedView text={text} entities={entities} />;
            }
            return <RequestExplorerSplitView leftHtml={rightOriginalPreviewHtml} />;
        } else {
            if (showDiffFromOriginalToModified) {
                if (displayMode === 'Identification') {
                    return (
                        <QuiBox display={'flex'} style={{ height: '100%' }}>
                            <RequestExplorerUnifiedView
                                title={'ORIGINAL'}
                                text={text}
                                entities={entities}
                                titleClassName={styles.modifiedDiffOriginalTitle}
                            />
                            <div className={styles.modifiedDiffBoxSeparator} />
                            <RequestExplorerUnifiedView
                                title={'MODIFIED'}
                                text={text}
                                entities={modifiedEntities}
                                titleClassName={styles.modifiedDiffModifiedTitle}
                            />
                        </QuiBox>
                    );
                }
                return (
                    <RequestExplorerSplitView
                        leftHtml={rightOriginalPreviewHtml}
                        rightHtml={rightModifiedPreviewHtml}
                        leftTitle={'ORIGINAL'}
                        rightTitle={'MODIFIED'}
                    />
                );
            } else {
                if (displayMode === 'Identification') {
                    return (
                        <RequestExplorerUnifiedView
                            titleClassName={styles.modifiedDisclaimer}
                            title={'Modified Results'}
                            text={text}
                            entities={modifiedEntities}
                        />
                    );
                }
                return <RequestExplorerSplitView leftTitle={'Modified Results'} leftHtml={rightModifiedPreviewHtml} />;
            }
        }
    };

    const clearOptions = () => {
        setDisplayMode('Identification');
        setShowDiffFromOriginalToModified(false);
    };

    const handleClearList = () => {
        setAllowList(labelAllowLists);
        setBlockList(labelBlockLists);
        clearOptions();
        resetModifyRequest();
    };

    const handleBack = () => {
        handleClearList();
        clearOptions();
        navigate('/request-explorer');
    };

    return (
        <QuiBox style={{ maxWidth: 'unset', height: 'calc(100vh - 75px)', overflow: 'hidden' }} display={'flex'} flexDirection={'row'} gap={'md'}>
            <QuiBox display={'flex'} flexGrow={'1'} flexDirection={'row'} gap={'md'}>
                <QuiBox display={'flex'} flexDirection={'column'} style={{ height: '100%', width: '100%' }} border="stroke-base" borderRadius="md">
                    <QuiBox
                        bg={'background-base'}
                        borderBottom="stroke-base"
                        padding="sm"
                        text="mono-text-xs"
                        display={'flex'}
                        justifyContent={'space-between'}
                    >
                        <QuiBox flexGrow={'1'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <QuiBox display={'flex'} gap={'md'}>
                                <QuiButton variant={'outline-default'} iconLeft={'arrow-left'} onClick={handleBack}>
                                    Back
                                </QuiButton>
                                <IdentificiationVsReplacementButtonGroup selection={displayMode} onSelect={setDisplayMode} />
                                {displayMode === 'Replacement' && (
                                    <QuiBox className={styles.replacementLegend} display={'flex'} flexDirection={'column'}>
                                        <QuiBox display={'flex'} gap={'xs'} alignItems={'center'}>
                                            <div className={styles.synthesisLegendItem} />
                                            <QuiText size={'text-xs'}>Synthesized</QuiText>
                                        </QuiBox>
                                        <QuiBox display={'flex'} gap={'xs'} alignItems={'center'}>
                                            <div className={styles.tokenizationLegendItem} />
                                            <QuiText size={'text-xs'}>Tokenized</QuiText>
                                        </QuiBox>
                                    </QuiBox>
                                )}
                            </QuiBox>
                            <QuiBox display={'flex'} gap={'md'} alignItems={'center'}>
                                {hasModified && (
                                    <QuiSwitch
                                        className={styles.unifiedSwitch}
                                        label={'Diff'}
                                        onChange={() => setShowDiffFromOriginalToModified((current) => !current)}
                                        checked={showDiffFromOriginalToModified}
                                    />
                                )}
                                {!showEditOption && (
                                    <QuiButton variant={'primary'} iconLeft={'edit'} onClick={() => setShowEditOption(true)}>
                                        Edit
                                    </QuiButton>
                                )}
                                {showEditOption && (
                                    <QuiButton variant={'default'} onClick={() => setShowEditOption(false)}>
                                        Close Editor
                                    </QuiButton>
                                )}
                            </QuiBox>
                        </QuiBox>
                    </QuiBox>
                    <QuiBox text="mono-text-sm" style={{ overflow: 'auto' }}>
                        {getContent()}
                    </QuiBox>
                </QuiBox>
            </QuiBox>
            {showEditOption && (
                <EditRequest
                    id={requestId}
                    allowList={allowList}
                    blockList={blockList}
                    handleClearList={handleClearList}
                    setAllowList={setAllowList}
                    setBlockList={setBlockList}
                    generatorSetup={generatorSetup}
                    setGeneratorSetup={setGeneratorSetup}
                />
            )}
        </QuiBox>
    );
}
