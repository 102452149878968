import { useField } from 'react-final-form';
import { TitleBox } from '../../components/TitleBox/TitleBox';
import { CloudFileExplorer } from '../../components/CloudFileExplorer/CloudFileExplorer';
import { noop } from '../../utils';
import { QuiBox, QuiButton } from '@tonicai/ui-quinine';
import { useMemo, useState } from 'react';
import { FileSystemType } from '../../components/CloudFileExplorer/CloudFileExplorer';

const EMPTY_ARRAY: string[] = [];

type PathSelectorFieldProps = Readonly<{
    parseJobConfigId: string;
    fileSystemType: FileSystemType;
}>;

export function PathSelectorField({ parseJobConfigId, fileSystemType }: PathSelectorFieldProps) {
    const {
        input: { value: outputPath, onChange: setOutputPath },
    } = useField<string>('outputPath', {
        subscription: {
            value: true,
        },
    });

    const hasOutputPath = useMemo(() => !!outputPath, [outputPath]);
    const [showFileExplorer, setShowFileExplorer] = useState<boolean>(false);

    const filePrefix = (() => {
        switch (fileSystemType) {
            case 'aws':
                return 's3://';
            case 'azure':
                return 'azure://';
            case 'databricks':
                return '';
            default:
                return '';
        }
    })();

    return (
        <TitleBox padding="none" title="Select Output Location">
            <div>
                {outputPath ? (
                    <QuiBox display="flex" alignItems="center" gap="sm" padding="sm">
                        <QuiBox flexGrow="1">
                            <QuiBox
                                borderRadius="md"
                                border="stroke-base"
                                bg="background-alt"
                                padding="sm"
                                text="mono-text-xs"
                            >{`${filePrefix}${outputPath}`}</QuiBox>
                        </QuiBox>
                        <QuiButton
                            iconLeft="edit"
                            variant="minimal"
                            type="button"
                            onClick={() => {
                                setShowFileExplorer((c) => !c);
                            }}
                        />
                    </QuiBox>
                ) : (
                    <QuiBox bg="background-neutral" text="mono-text-xs" borderBottom="stroke-base" color="text-base--disabled" padding="sm">
                        Select an output path below
                    </QuiBox>
                )}
                {showFileExplorer || !hasOutputPath ? (
                    <CloudFileExplorer
                        disableNestedPaths={false}
                        allowMultipleSelection={false}
                        pathPrefixes={[outputPath]}
                        selectedFiles={EMPTY_ARRAY}
                        allowFileSelection={false}
                        onFileSelected={noop}
                        parseJobConfigId={parseJobConfigId}
                        onPathSelected={(pathSelected) => {
                            setOutputPath(pathSelected);
                            setShowFileExplorer(false);
                        }}
                        fileSystemType={fileSystemType}
                    />
                ) : null}
            </div>
        </TitleBox>
    );
}
