import { QuiBox, QuiButton, QuiConditionalField, QuiFlexBoxColumn, QuiPasswordField, QuiTextField, QuiSelectField } from '@tonicai/ui-quinine';
import styles from '@/components/CreatePipelineDialog/CreatePipelineDialog.module.scss';
import { SourceType } from '@/components/CreatePipelineDialog/SourceType';
import { Message } from '@/components/Message/Message';
import { AWS_S3_REGIONS_OPTIONS } from '@/types';
import { requiredString } from '@/validation';

interface AWSCredentialsProps {
    testStatus: 'untested' | 'testing' | 'success' | 'error';
    testConnectionEnabled: boolean | undefined;
    submitting: boolean;
    onClick: () => void;
    errorToShow: 'form' | 'test' | 'none';
    testErrorMessage?: string | null;
}

export function AWSCredentials({ testStatus, testConnectionEnabled, submitting, onClick, errorToShow, testErrorMessage }: AWSCredentialsProps) {
    return (
        <QuiConditionalField comparision="equal" fieldName="fileSource" value={SourceType.Aws}>
            <QuiConditionalField comparision="equal" fieldName="useEnvironmentCredentials" value={true}>
                <QuiBox text="text-sm" color="text-subdued">
                    Credentials are sourced from the environment.
                </QuiBox>
            </QuiConditionalField>

            <QuiConditionalField comparision="equal" fieldName="useEnvironmentCredentials" value={false}>
                <QuiFlexBoxColumn className={styles.awsCredentials} gap="md">
                    <QuiBox weight="medium" text="text-sm">
                        Amazon S3 Credentials
                    </QuiBox>

                    <QuiTextField size="sm" validate={requiredString} label="Access Key" name="awsAccessKey" id="aws-access-key" />

                    <QuiPasswordField size="sm" validate={requiredString} label="Secret Access Key" name="awsAccessSecret" id="aws-secret-key" />

                    <QuiSelectField
                        validate={requiredString}
                        label="Region"
                        name="awsRegion"
                        id="aws-region"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        size="sm"
                        options={AWS_S3_REGIONS_OPTIONS}
                        menuPortalTarget={document.body}
                    />

                    <QuiPasswordField size="sm" label="Session Token" name="awsSessionToken" id="aws-session-token" />

                    <QuiButton
                        spinner={testStatus === 'testing'}
                        iconRight="globe"
                        type="button"
                        size="sm"
                        disabled={!testConnectionEnabled || testStatus === 'testing' || submitting}
                        onClick={onClick}
                    >
                        Test AWS Connection
                    </QuiButton>

                    {/* Success Message */}
                    {errorToShow === 'test' && testStatus === 'success' && <Message variant="success">Successfully connected to AWS</Message>}

                    {/* Error Message */}
                    {errorToShow === 'test' && testStatus === 'error' && (
                        <Message variant="error">{testErrorMessage ?? 'Could not connect to AWS'}</Message>
                    )}
                </QuiFlexBoxColumn>
            </QuiConditionalField>
        </QuiConditionalField>
    );
}
