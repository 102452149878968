import {
    QuiBox,
    QuiButton,
    QuiFlexBoxColumn,
    QuiFormProps,
    QuiModalContent,
    QuiModalDialog,
    QuiModalForm,
    QuiSubmitButton,
    QuiText,
} from '@tonicai/ui-quinine';
import { ReactNode } from 'react';
import { FormError } from '../FormError/FormError';
import styles from './CreateDialog.module.scss';

export type CreateDialogProps<T> = Readonly<{
    isOpen: boolean;
    onClose: () => void;
    onSubmit: QuiFormProps<T>['onSubmit'];
    initialValues: T;
    children: ReactNode;
    description: ReactNode;
    itemName: string;
    className?: string;
    hideFormError?: boolean;
}>;

export function CreateDialog<T>({
    children,
    description,
    isOpen,
    onClose,
    initialValues,
    itemName,
    onSubmit,
    className,
    hideFormError,
}: CreateDialogProps<T>) {
    return (
        <QuiModalDialog transitionDuration={0} className={styles.createDialog} isOpen={isOpen} onClose={onClose}>
            <QuiModalForm<T> initialValues={initialValues} onSubmit={onSubmit}>
                <QuiModalContent>
                    <QuiFlexBoxColumn className={className} gap="lg">
                        <QuiBox padding="md none none none">
                            <QuiText size="text-md" weight="medium">
                                {`Create A New ${itemName}`}
                            </QuiText>
                            <QuiText size="text-sm" color="text-subdued">
                                {description}
                            </QuiText>
                        </QuiBox>
                        <QuiBox display="flex" flexDirection="column" gap="md">
                            {children}
                        </QuiBox>
                        {hideFormError ? null : <FormError />}
                        <QuiBox display="flex" alignItems="center" gap="md" flexGrow="1">
                            <QuiSubmitButton data-test="create-dialog-save-button" variant="brand-purple">
                                Save
                            </QuiSubmitButton>
                            <QuiButton type="button" onClick={onClose}>
                                Cancel
                            </QuiButton>
                        </QuiBox>
                    </QuiFlexBoxColumn>
                </QuiModalContent>
            </QuiModalForm>
        </QuiModalDialog>
    );
}
