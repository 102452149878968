import { atom, useAtomValue } from 'jotai';
import { client } from '../services/HTTPClient';
import { globalStore } from './globalStore';
import axios from 'axios';
import { useMemo } from 'react';
import { getBlob } from '../utils';

type FileBlobData =
    | {
          fetchStatus: 'init' | 'loading' | 'error';
      }
    | {
          blob: string;
          fetchStatus: 'success';
      };

export const fileBlobsAtom = atom<Record<string, FileBlobData>>({});

export function fetchFileBlob(datasetId: string, fileId: string, signal?: AbortSignal) {
    const fileBlobState = globalStore.get(fileBlobsAtom)?.[fileId];

    const initialStatus = fileBlobState?.fetchStatus;

    if (initialStatus === 'loading' || initialStatus === 'success') {
        // Its either being fetched or it's already been fetched
        // successfully, so we don't need to fetch it again.
        return;
    }

    globalStore.set(fileBlobsAtom, (current) => {
        return {
            ...current,
            [fileId]: {
                pdfBlob: undefined,
                fetchStatus: 'loading',
            },
        };
    });

    client
        .get<string>(`/api/preview/${datasetId}/files/${fileId}/original`, {
            signal,
        })
        .then(({ data }) => {
            globalStore.set(fileBlobsAtom, (current) => {
                return {
                    ...current,
                    [fileId]: {
                        fetchStatus: 'success',
                        blob: getBlob(data),
                    },
                };
            });
        })
        .catch((e) => {
            if (!axios.isCancel(e)) {
                globalStore.set(fileBlobsAtom, (current) => {
                    return {
                        ...current,
                        [fileId]: {
                            pdfBlob: undefined,
                            fetchStatus: 'error',
                        },
                    };
                });
            } else {
                globalStore.set(fileBlobsAtom, (current) => {
                    const newValue = { ...current };
                    delete newValue[fileId];
                    return newValue;
                });
            }
        });
}

const fallbackValue: FileBlobData = {
    fetchStatus: 'init',
};

export function useFileBlob(fileId: string | undefined): FileBlobData {
    const fileBlobAtom = useMemo(() => {
        return atom<FileBlobData>((get) => {
            if (!fileId) return fallbackValue;

            const value = get(fileBlobsAtom)?.[fileId];

            if (!value) return fallbackValue;

            return value;
        });
    }, [fileId]);

    return useAtomValue(fileBlobAtom);
}
