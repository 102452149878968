import { QuiSpinner } from '@tonicai/ui-quinine';
import { useEffect, useState } from 'react';

type ScalableImageProps = Readonly<{
    src: string;
    scale: number;
    onLoad: (dimensions: { width: number; height: number }) => void;
}>;

export function ScalableImage({ src, scale, onLoad }: ScalableImageProps) {
    const [dimensions, setDimensions] = useState<{
        width: number;
        height: number;
    } | null>(null);

    useEffect(() => {
        const img = new Image();
        img.src = src;

        setDimensions(null);

        function onLoadHandler() {
            const dimensions: { width: number; height: number } = {
                width: img.width,
                height: img.height,
            };
            setDimensions(dimensions);
            onLoad(dimensions);
        }

        img.addEventListener('load', onLoadHandler);

        return () => {
            img.removeEventListener('load', onLoadHandler);
        };
    }, [src, onLoad]);

    if (!dimensions) {
        return <QuiSpinner />;
    }

    return (
        <img
            alt=""
            src={src}
            width={dimensions.width}
            height={dimensions.height}
            style={{
                display: 'block',
                width: `${dimensions.width * scale}px`,
                height: `${dimensions.height * scale}px`,
            }}
        />
    );
}
