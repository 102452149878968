import { TextualUseCase } from '@/types';
import { requiredString } from '@/validation';
import { QuiBox, QuiCheckbox, QuiField, QuiText, QuiTextField } from '@tonicai/ui-quinine';
import { useField, useForm } from 'react-final-form';

type TextualUseCaseFieldProps = Readonly<{
    id?: string;
    name: string;
    otherFieldName: string;
    label: string;
    helperText?: string;
}>;

export function TextualUseCaseField({ id, name, otherFieldName, helperText, label }: TextualUseCaseFieldProps) {
    const useCaseField = useField<TextualUseCase[]>(name, {
        subscription: { value: true, touched: true, error: true },
        validate: (value) => {
            if (!value || value.length === 0) {
                return 'At least one use case must be selected';
            }
            return undefined;
        },
    });

    const form = useForm();

    const handleCheckboxChange = (useCase: TextualUseCase) => {
        const isSelected = useCaseField.input.value.includes(useCase);
        useCaseField.input.onChange(
            isSelected ? useCaseField.input.value.filter((item) => item !== useCase) : [...useCaseField.input.value, useCase]
        );
        form.mutators.setFieldTouched(name);
    };

    return (
        <>
            <QuiField id={id} label={label} helperText={helperText}>
                <QuiCheckbox
                    label={'Generate redacted or de-identified versions of your documents'}
                    onChange={() => {
                        handleCheckboxChange(TextualUseCase.GenerateRedactedDocumentVersions);
                    }}
                    value={useCaseField.input.value.includes(TextualUseCase.GenerateRedactedDocumentVersions)}
                />
                <QuiCheckbox
                    label={'Build unstructured data pipelines to power RAG systems'}
                    onChange={() => {
                        handleCheckboxChange(TextualUseCase.BuildUnstructuredDataPipelinesForRAG);
                    }}
                    value={useCaseField.input.value.includes(TextualUseCase.BuildUnstructuredDataPipelinesForRAG)}
                />
                <QuiCheckbox
                    label={'Improve the quality of your RAG system with better data preparation'}
                    onChange={() => {
                        handleCheckboxChange(TextualUseCase.ImproveRAGSystemWithDataPreparation);
                    }}
                    value={useCaseField.input.value.includes(TextualUseCase.ImproveRAGSystemWithDataPreparation)}
                />
                <QuiCheckbox
                    label={'De-identify sensitive data before ingesting into a RAG or LLM application'}
                    onChange={() => {
                        handleCheckboxChange(TextualUseCase.DeidentifySensitiveDataBeforeRAGorLLMIngestion);
                    }}
                    value={useCaseField.input.value.includes(TextualUseCase.DeidentifySensitiveDataBeforeRAGorLLMIngestion)}
                />
                <QuiCheckbox
                    label={'Other'}
                    onChange={() => {
                        handleCheckboxChange(TextualUseCase.Other);
                    }}
                    value={useCaseField.input.value.includes(TextualUseCase.Other)}
                />
            </QuiField>
            {useCaseField.input.value.includes(TextualUseCase.Other) && (
                <QuiBox padding="none none none 2lg">
                    <QuiTextField placeholder={'Please Specify...'} name={otherFieldName} validate={requiredString} />
                </QuiBox>
            )}
            {useCaseField.meta.touched && useCaseField.meta.error && (
                <QuiText color={'text-danger'} size={'text-xs'} id={`${id}-error`} aria-live="assertive" data-test="qui-field-error">
                    {useCaseField.meta.error}
                </QuiText>
            )}
        </>
    );
}
