import { numberConfig } from '@/pages/Dataset/DateTimeGeneratorMetadataField';
import { GeneratorMetadataProps, ModelTransformation } from '@/types';
import { QuiBox, QuiCheckboxField, QuiNumberField, QuiSelectField } from '@tonicai/ui-quinine';
import { useForm, useFormState } from 'react-final-form';

export function PersonAgeGeneratorMetadataField({ name, automaticallySubmit }: GeneratorMetadataProps) {
    const form = useForm();
    const formState = useFormState({ subscription: { submitting: true } });
    return (
        <QuiBox display="flex" flexDirection="column" gap="sm">
            <QuiBox borderRadius="md" border="stroke-base" text="text-xs">
                <QuiBox display="flex" flexDirection="column" borderRadius="md" padding={'md'} gap={'md'}>
                    <QuiSelectField
                        onBlur={() => {
                            if (automaticallySubmit) {
                                form.submit();
                            }
                        }}
                        isClearable={false}
                        name={`${name}.dateTimeTransformation`}
                        label="Transformation"
                        placeholder=""
                        options={[
                            {
                                value: ModelTransformation.AgeShift,
                                label: 'Age Shift',
                            },
                        ]}
                    />
                    <QuiNumberField
                        config={numberConfig}
                        onBlur={() => {
                            if (automaticallySubmit) {
                                form.submit();
                            }
                        }}
                        label="Range of Years +/- for the Shifted Age"
                        name={`${name}.metadata.ageShiftInYears`}
                    />
                    <QuiCheckboxField
                        name={`${name}.scrambleUnrecognizedDates`}
                        label={'Scramble Unrecognized Ages'}
                        helperText={
                            'When enabled, ages that cannot be parsed are randomly scrambled. If disabled, the values are passed through unchanged.'
                        }
                        disabled={formState.submitting}
                    />
                </QuiBox>
            </QuiBox>
        </QuiBox>
    );
}
