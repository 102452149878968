import { atom, useAtomValue } from 'jotai';
import { EnvironmentModels, SolarEnvironmentVariable, SolarEnvironmentVariables } from '../utils/environment';
import { createGetEndpoint } from './atom-creators';

export const {
    dataAtom: environmentVariablesAtom,
    fetchData: fetchEnvironmentVariables,
    statusAtom: environmentVariablesStatusAtom,
} = createGetEndpoint<SolarEnvironmentVariables>('/api/environment');

export const {
    dataAtom: environmentModelsAtom,
    fetchData: fetchEnvironmentModels,
    statusAtom: environmentModelsStatusAtom,
} = createGetEndpoint<EnvironmentModels>('/api/environment/models');

const ocrEnabledAtom = atom((get) => {
    return get(environmentVariablesAtom)?.[SolarEnvironmentVariable.OcrEnabled] === true;
});

export function useIsOcrEnabled() {
    return useAtomValue(ocrEnabledAtom);
}

export function useIsSharepointUploadEnabled() {
    return useAtomValue(environmentVariablesAtom)?.[SolarEnvironmentVariable.SolarSharepointUploadEnabled] === true;
}

export const internalBucketSetAtom = atom((get) => {
    return get(environmentVariablesAtom)?.[SolarEnvironmentVariable.INTERNAL_BUCKET_SET] === true;
});

export const isHostedProdAtom = atom((get) => {
    return get(environmentVariablesAtom)?.[SolarEnvironmentVariable.IsHostedProd] === true;
});

const areAwsCredsAvailableAtom = atom((get) => {
    return get(environmentVariablesAtom)?.[SolarEnvironmentVariable.ARE_AWS_CREDS_AVAILABLE] === true;
});

export function useInternalBucketSet() {
    return useAtomValue(internalBucketSetAtom);
}

export function useIsHostedProd() {
    return useAtomValue(isHostedProdAtom);
}

export const hasOpenAiKeyAtom = atom((get) => {
    return get(environmentVariablesAtom)?.[SolarEnvironmentVariable.HAS_OPENAI_API_KEY] === true;
});

export function useAreAwsCredsAvailable() {
    return useAtomValue(areAwsCredsAvailableAtom);
}
