import { QuiBox, QuiFlexV, TonicTextualHorizontal } from '@tonicai/ui-quinine';
import { ReactNode } from 'react';
import styles from './Auth.module.scss';

type AuthProps = Readonly<{
    children: ReactNode;
    hideBorder?: boolean;
}>;

export function Auth({ children, hideBorder }: AuthProps) {
    return (
        <>
            <QuiBox display="flex" alignItems="center" justifyContent="center" padding="lg" gap="sm" text="display-xs">
                <TonicTextualHorizontal className={styles.logo} />
            </QuiBox>
            <QuiBox bg="background-base" padding="md" borderRadius="lg" border={hideBorder ? undefined : 'stroke-base'}>
                <QuiFlexV gap="md" padding="md">
                    {children}
                </QuiFlexV>
            </QuiBox>
        </>
    );
}
