import { HelpDocumentation } from '@/components/HelpDocumentation/HelpDocumentation';
import { PageTitle } from '@/components/PageTitle/PageTitle';
import { useBillingStatus } from '@/stores';
import { CloudSubscriptionStatus } from '@/types';
import { QuiBox, QuiFlexBoxColumn, QuiFlexH } from '@tonicai/ui-quinine';
import { BillingMetadata } from './BillingMetadata';
import { ManagePaymentButton } from './ManagePaymentButton';
import { SubscriptionBadge } from './SubscriptionBadge';
import { UpgradePlanButton } from './UpgradePlanButton';

export function WordCountLeftSidebar() {
    const billingStatus = useBillingStatus();
    return (
        <>
            <QuiBox display="flex" alignItems="start" flexDirection="column" gap="md" padding="none lg none none">
                <PageTitle icon="flag" title="Manage Plan" />
            </QuiBox>
            <QuiFlexBoxColumn gap="md" justifyContent="space-between" style={{ height: '100%' }}>
                <QuiBox borderRadius="md" border="stroke-base" padding="lg" margin="lg none none none">
                    <QuiFlexBoxColumn gap="sm">
                        {billingStatus ? (
                            <QuiBox>
                                <QuiBox margin="none none sm none" text="text-xs">
                                    Your Plan
                                </QuiBox>
                                <SubscriptionBadge status={billingStatus} />
                            </QuiBox>
                        ) : null}

                        <BillingMetadata subscription lastFour customerName wordsUsed />

                        <QuiBox margin="lg none none none">
                            <QuiFlexH gap="sm">
                                {billingStatus === CloudSubscriptionStatus.Active ? <ManagePaymentButton /> : <UpgradePlanButton />}
                            </QuiFlexH>
                        </QuiBox>
                    </QuiFlexBoxColumn>
                </QuiBox>
                <HelpDocumentation />
            </QuiFlexBoxColumn>
        </>
    );
}
