import { QuiBox, QuiButton, QuiFlexBoxColumn, QuiIcon, QuiSpinner, QuiTextInput } from '@tonicai/ui-quinine';
import { useEndpoint } from '../../hooks/useEndpoint';

type SearchBarProps = Readonly<{
    status: ReturnType<typeof useEndpoint>['fetchStatus'];
    value: string;
    setValue: (value: string) => void;
    isTruncated: boolean;
}>;

export function SearchBar({ status, value, isTruncated, setValue }: SearchBarProps) {
    return (
        <QuiFlexBoxColumn gap="xs" padding="sm" borderBottom="stroke-base">
            <QuiTextInput
                size="sm"
                type="search"
                iconLeft="search"
                placeholder="Search by prefix"
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setValue(e.target.value);
                }}
                iconRight={
                    <>
                        {status === 'loading' || status === 'refreshing' || status === 'init' ? <QuiSpinner size="sm" /> : null}
                        {value.length > 0 ? (
                            <QuiButton
                                type="button"
                                iconLeft="x"
                                variant="minimal"
                                size="sm"
                                onClick={() => {
                                    setValue('');
                                }}
                            />
                        ) : null}
                    </>
                }
            />
            {isTruncated ? (
                <QuiBox display="flex" color="text-base--disabled" gap="xs" alignItems="center" text="text-xs">
                    <QuiIcon icon="info" size="sm" />
                    <span>Only 1000 results are shown. Use search to narrow results.</span>
                </QuiBox>
            ) : null}
        </QuiFlexBoxColumn>
    );
}
