import { QuiBox, QuiButton } from '@tonicai/ui-quinine';
import styles from './IdentificiationVsSynthesisButtonGroup.module.scss';
export type DisplayMode = 'Identification' | 'Replacement';

type Props = {
    selection: DisplayMode;
    onSelect: (selection: DisplayMode) => void;
};

export function IdentificiationVsReplacementButtonGroup({ selection, onSelect }: Props) {
    return (
        <QuiBox display={'flex'}>
            <QuiButton
                className={styles.left}
                onClick={() => onSelect('Identification')}
                variant={selection === 'Identification' ? 'primary' : 'default'}
            >
                Identification
            </QuiButton>
            <QuiButton className={styles.right} onClick={() => onSelect('Replacement')} variant={selection === 'Replacement' ? 'primary' : 'default'}>
                Replacement
            </QuiButton>
        </QuiBox>
    );
}
