import { PrimitiveAtom, atom, useAtomValue } from 'jotai';
import { JotaiStore, globalStore } from './globalStore';
import axios, { AxiosResponse } from 'axios';
import { client } from '../services/HTTPClient';
import { FetchStatus, JSONValue } from '../types';
import isEqual from 'fast-deep-equal';
import { atomWithReducer } from 'jotai/utils';

// Makes atoms that only send updates if their new values are different from their
// previous values. Useful for values that are polled from the server and don't
// actually change as often as they are fetched.
export function atomWithCompare<Value extends JSONValue>(initialValue: Value) {
    return atomWithReducer(initialValue, (prev: Value, next: Value) => {
        if (isEqual(prev, next)) {
            // This is important to return the previous value because Jotai
            // will do an Object.is() comparision and this will show that the
            // value hasn't changed. If we cloned the previous value
            // (e.g. `return {...prev}`) then atomWithCompare wouldn't work
            // as expected.
            return prev;
        }

        return next;
    });
}

/**
 * These functions create atoms that are computed from other atoms, so that we
 * don't re-create the same derived data values over and over again.
 */

// Parse the value of an atom with a FetchStatus value into a boolean value that
// lets you know whether to show a loading spinner or not. For cases where you
// need to fetch data before showing an error or not.
export function createLoadedAtomFromFetchStatusAtom(fetchStatusAtom: PrimitiveAtom<FetchStatus>) {
    return atom((get) => {
        const status = get(fetchStatusAtom);
        return status === 'refreshing' || status === 'success' || status === 'error';
    });
}

export function createGetEndpoint<T>(path: string, store: JotaiStore = globalStore) {
    const dataAtom = atom<T | null>(null);
    const statusAtom = atom<FetchStatus>('init');
    const dataLoadedAtom = atom<boolean>((get) => ['success', 'refreshing'].includes(get(statusAtom)));

    let abortController = new AbortController();

    function fetchData() {
        abortController.abort();

        abortController = new AbortController();

        store.set(statusAtom, (currentValue) => (currentValue === 'success' || currentValue === 'refreshing' ? 'refreshing' : 'loading'));

        return client
            .get<T>(path, { signal: abortController.signal })
            .then(({ data }) => {
                store.set(dataAtom, (currentValue) => {
                    // Due to Object.is equality check, we need to return the same object
                    // if the value hasn't changed to prevent unnecessary re-renders.
                    if (isEqual(currentValue, data)) {
                        return currentValue;
                    } else {
                        return data;
                    }
                });
                store.set(statusAtom, 'success');
                return data;
            })
            .catch((e) => {
                if (!axios.isCancel(e)) {
                    store.set(statusAtom, 'error');
                }

                return null;
            });
    }

    function cancelFetching() {
        abortController.abort();
    }

    function useDataValue() {
        return useAtomValue(dataAtom);
    }

    return {
        dataAtom,
        statusAtom,
        dataLoadedAtom,
        fetchData,
        cancelFetching,
        useDataValue,
    };
}

function filterItemsWithSameId<T extends { id: string }>(items: T[] | null, id: string): T[] {
    if (!items) {
        return [];
    }
    return items.filter((item) => item.id !== id);
}

export function crudGenerator<ResponseType extends { id: string }, CreateType, UpdateType = CreateType>(path: string) {
    const { dataAtom, statusAtom, dataLoadedAtom, fetchData, useDataValue } = createGetEndpoint<ResponseType[]>(path);

    function createItem(item: CreateType) {
        return client.post<ResponseType, AxiosResponse<ResponseType>, CreateType>(path, item).then(({ data }) => {
            globalStore.set(dataAtom, (items) => [...filterItemsWithSameId(items, data.id), data]);

            return data;
        });
    }

    function updateItem(itemId: string, item: UpdateType) {
        return client.patch<ResponseType>(`${path}/${itemId}`, item).then(async () => {
            return await fetchItem(itemId);
        });
    }

    function deleteItem(itemId: string) {
        return client.delete(`${path}/${itemId}`).then(() => {
            globalStore.set(dataAtom, (items) => filterItemsWithSameId(items, itemId));
        });
    }

    function fetchItem(itemId: string, signal?: AbortSignal) {
        return client.get<ResponseType>(`${path}/${itemId}`, { signal }).then(({ data }) => {
            globalStore.set(dataAtom, (items) => [...filterItemsWithSameId(items, data.id), data]);
            return data;
        });
    }

    return {
        dataAtom,
        statusAtom,
        dataLoadedAtom,
        fetchItem,
        fetchData,
        createItem,
        updateItem,
        deleteItem,
        useDataValue,
    };
}
