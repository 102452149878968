import { QuiFlexV, QuiLink, QuiPasswordField, QuiSubmitButton, QuiText, QuiTextField } from '@tonicai/ui-quinine';
import { useFormState } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { passwordValidator, confirmPasswordValidator } from '@/utils';
import { SubmitError } from '@/components/Auth/SubmitError';

export function ResetPasswordFormBody() {
    const { submitting, submitSucceeded, dirtySinceLastSubmit } = useFormState();

    if (submitSucceeded) {
        return (
            <QuiFlexV gap="lg">
                <QuiText size={'text-sm'} weight={'normal'}>
                    Password reset successfully. You can now log in with your new password.
                </QuiText>

                <QuiLink size="text-sm" to="/login" variant="currentcolor" data-test="signin-link">
                    Back to Log In
                </QuiLink>
            </QuiFlexV>
        );
    }

    return (
        <QuiFlexV gap="lg">
            <QuiTextField name="email" label="Email address" disabled={true} data-test="email-input" />
            <QuiPasswordField name="password" label="Password *" disabled={submitting} validate={passwordValidator} data-test="password-input" />
            <QuiPasswordField
                name="confirmPassword"
                label="Confirm Password *"
                validate={confirmPasswordValidator('password') as FieldValidator<string>}
                disabled={submitting}
                data-test="confirm-password-input"
            />

            <QuiSubmitButton size="lg" variant="primary" data-test="reset-password-button">
                Reset Password
            </QuiSubmitButton>
            {!dirtySinceLastSubmit && <SubmitError />}
        </QuiFlexV>
    );
}
