// src/components/ToastListener.tsx
import React, { useRef } from 'react';
import { useAtom } from 'jotai';
import { useDismissableQuiToasts } from '@tonicai/ui-quinine';
import { httpClientToastAtom, HttpClientToastMessage } from '@/stores/httpToast';

export function HttpToastListener() {
    const [toasts, setToasts] = useAtom(httpClientToastAtom);
    const { addToast, dismissToast } = useDismissableQuiToasts();
    const toastRef = useRef<string>();

    React.useEffect(() => {
        if (toasts.length > 0) {
            // Display each toast
            toasts.forEach((toast: HttpClientToastMessage) => {
                if (toastRef.current) {
                    dismissToast(toastRef.current);
                    toastRef.current = undefined;
                }
                toastRef.current = addToast({
                    title: toast.title,
                    variant: toast.variant,
                    action: toast.action,
                });
            });

            setToasts([]);
        }
    }, [toasts, addToast, setToasts, dismissToast]);

    return null;
}
