import { QuiBox, QuiButton } from '@tonicai/ui-quinine';
import { isCancel } from 'axios';
import isEqual from 'fast-deep-equal';
import { FieldSubscription } from 'final-form';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useField, useFormState } from 'react-final-form';
import { client } from '../../services/HTTPClient';
import { AwsCredential, FileSourceEnum } from '../../types';
import { FormError } from '../FormError/FormError';
import { Message } from '../Message/Message';

const fieldSubscription: FieldSubscription = {
    value: true,
    valid: true,
    dirtySinceLastSubmit: true,
    touched: true,
    active: true,
    pristine: true,
    modifiedSinceLastSubmit: true,
    modified: true,
};

export interface ITestAwsResponse {
    success: boolean;
    errorMessage: string | undefined;
}

type TestAwsCredentialsButtonProps = Readonly<{
    awsKeyFieldName: string;
    awsAccessSecretFieldName: string;
    awsRegionFieldName: string;
    awsSessionTokenFieldName: string;
    onClick?: () => void;
    showFormError?: boolean;
}>;

export function TestAwsCredentialsButton({
    awsKeyFieldName,
    awsAccessSecretFieldName,
    awsRegionFieldName,
    awsSessionTokenFieldName,
    onClick,
    showFormError,
}: TestAwsCredentialsButtonProps) {
    const form = useFormState({
        subscription: {
            submitting: true,
            submitError: true,
            submitFailed: true,
        },
    });
    const awsKeyField = useField(awsKeyFieldName, {
        subscription: fieldSubscription,
    });
    const awsAccessSecretField = useField(awsAccessSecretFieldName, {
        subscription: fieldSubscription,
    });
    const awsRegionField = useField(awsRegionFieldName, {
        subscription: fieldSubscription,
    });
    const awsSessionToken = useField(awsSessionTokenFieldName, {
        subscription: fieldSubscription,
    });

    const lastCheckedValues = useRef<AwsCredential | null>(null);
    const abortControllerRef = useRef<AbortController | null>(null);

    const [testStatus, setTestStatus] = useState<'untested' | 'testing' | 'success' | 'error'>('untested');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const testConnectionEnabled = useMemo(() => {
        return awsKeyField.meta.valid && awsAccessSecretField.meta.valid && awsRegionField.meta.valid;
    }, [awsKeyField, awsAccessSecretField, awsRegionField]);

    useEffect(() => {
        if (form.submitting) {
            setTestStatus('untested');
            setErrorMessage(null);
        }
    }, [form.submitting]);

    useEffect(() => {
        setTestStatus('untested');
        setErrorMessage(null);
    }, [awsKeyField.input.value, awsAccessSecretField.input.value, awsRegionField.input.value, awsSessionToken.input.value]);

    const testAWSConnection = useCallback(
        (automatic?: boolean) => {
            const data: AwsCredential = {
                accessKey: awsKeyField.input.value,
                secretKey: awsAccessSecretField.input.value,
                region: awsRegionField.input.value,
                sessionToken: awsSessionToken.input.value,
            };

            if (automatic && lastCheckedValues.current !== null && isEqual(data, lastCheckedValues.current)) {
                return;
            }

            abortControllerRef.current?.abort();
            abortControllerRef.current = new AbortController();

            lastCheckedValues.current = data;

            setTestStatus('testing');
            setErrorMessage(null);
            client
                .post<ITestAwsResponse>(
                    `/api/cloudfile/test-cloud-connection`,
                    {
                        fileSource: FileSourceEnum.Aws,
                        credential: data,
                    },
                    { signal: abortControllerRef.current.signal }
                )
                .then((response) => {
                    if (response.data.success) {
                        setTestStatus('success');
                        setErrorMessage(null);
                    } else {
                        setTestStatus('error');
                        setErrorMessage(response.data.errorMessage ?? 'Error');
                    }
                })
                .catch((error) => {
                    if (!isCancel(error)) {
                        console.error(error);
                        setTestStatus('error');
                        setErrorMessage('Could not connect to AWS');
                    }
                });
        },
        [awsKeyField, awsAccessSecretField, awsRegionField, awsSessionToken]
    );

    useEffect(() => {
        return () => {
            abortControllerRef.current?.abort();
        };
    }, []);

    return (
        <>
            <QuiButton
                spinner={testStatus === 'testing'}
                iconRight="globe"
                type="button"
                disabled={!testConnectionEnabled || testStatus === 'testing'}
                onClick={() => {
                    testAWSConnection(false);
                    if (onClick) {
                        onClick();
                    }
                }}
            >
                Test AWS Connection
            </QuiButton>

            {/* Success Message */}
            {testStatus === 'success' ? <Message variant="success">Successfully connected to AWS</Message> : null}

            {/* Error Message */}
            {testStatus === 'error' && !showFormError ? (
                <QuiBox>
                    <Message variant="error">{errorMessage}</Message>
                </QuiBox>
            ) : null}

            {showFormError ? <FormError /> : null}
        </>
    );
}
