import { QuiBadge, QuiBox, QuiIconToken, QuiText } from '@tonicai/ui-quinine';
import { ReactNode } from 'react';
import { PlainLink } from '../PlainLink/PlainLink';

type PageTitleProps = Readonly<{
    icon?: QuiIconToken;
    title: ReactNode;
    children?: ReactNode;
    to?: string;
}>;

export function PageTitle({ icon, title, children, to }: PageTitleProps) {
    return (
        <QuiBox display="flex" flexDirection="column" gap="md">
            <QuiBox display="flex" alignItems="center" gap="sm" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {icon && !to ? <QuiBadge size="lg" iconLeft={icon} variant="brand-purple" /> : null}
                {icon && to ? (
                    <PlainLink to={to}>
                        <QuiBadge size="lg" iconLeft={icon} variant="brand-purple" />
                    </PlainLink>
                ) : null}
                <QuiBox flexGrow="1" overflow="hidden">
                    {to ? (
                        <PlainLink to={to}>
                            <QuiText size="display-xs" weight="medium" as="span">
                                {title}
                            </QuiText>
                        </PlainLink>
                    ) : (
                        <QuiText size="display-xs" weight="medium">
                            {title}
                        </QuiText>
                    )}
                </QuiBox>
            </QuiBox>
            {children ? (
                <QuiText size="text-md" color="text-subdued">
                    {children}
                </QuiText>
            ) : null}
        </QuiBox>
    );
}
