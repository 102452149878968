import { QuiBox, QuiText } from '@tonicai/ui-quinine';
import { PiiTextExample, PiiTypeEnum } from '@/types';
import { EntityManagerModal } from './EntityManagerModal';
import { RedactionTypeExample } from './RedactionTypeExample';
import { getPiiTypeInfo } from './utils';

type RedactionTypeExamplesProps = Readonly<{
    piiType: PiiTypeEnum;
    examples: PiiTextExample[];
    totalExamples: number;
}>;
export function RedactionTypeExamples({ examples, piiType, totalExamples }: RedactionTypeExamplesProps) {
    const headerText = examples.length
        ? `Preview ${examples.length} of ${totalExamples} ${getPiiTypeInfo(piiType)?.description.toLowerCase()}`
        : `No previews of the ${getPiiTypeInfo(piiType)?.description.toLowerCase()} found`;
    // TODO: Remove this once we have a proper key
    let i = 0;
    return (
        <QuiBox padding={'sm'} gap={'xs'} display={'flex'} flexDirection={'column'}>
            <QuiBox color={'text-brand-black-600'} className={examples.length ? 'qui-border-bottom-white-500' : ''} padding={'xs'}>
                <QuiText size={'text-xs'}> {headerText}</QuiText>
            </QuiBox>
            {examples.map((example) => (
                <RedactionTypeExample
                    piiTextExample={example}
                    key={`${piiType}-example-${example.endIndex}-${example.endIndex}-${example.text}-${i++}`}
                />
            ))}
            <EntityManagerModal initialEntityType={piiType} />
        </QuiBox>
    );
}
