import { useCallback, useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import mutators from 'final-form-arrays';
import { createDatasetFormState, fetchDataset, updateDataset, usePIITypes } from '@/stores/datasets';
import { GeneratorSetup } from './GeneratorSetup';
import { DatasetInfo } from './DatasetInfo';
import { DatasetMeta } from './DatasetMeta';
import { Dataset as DatasetType, DatasetFormState, LabelBlockList } from '../../types';
import styles from './Dataset.module.scss';
import { Mutator } from 'final-form';
import { FilePreviewProvider } from '@/components/FilePreviews/FilePreviewProvider';
import { FilePreviewDialog } from '@/components/FilePreviews/FilePreviewDialog';
import { useDatasetContext } from '@/contexts/DatasetContext';

function useInitialListFieldValues(dataset: DatasetType, field: 'labelBlockLists' | 'labelAllowLists') {
    const piiTypes = usePIITypes();
    const datasetValues = dataset[field];

    const accumulator: Record<string, LabelBlockList> = {};
    return piiTypes?.reduce((accumulator, piiType) => {
        accumulator[piiType] = {
            regexes: datasetValues?.[piiType]?.regexes?.length ? datasetValues?.[piiType]?.regexes : [''],
            strings: datasetValues?.[piiType]?.strings?.length ? datasetValues?.[piiType]?.strings : [''],
        };
        return accumulator;
    }, accumulator);
}

export function Dataset() {
    const { dataset } = useDatasetContext();
    const labelBlockLists = useInitialListFieldValues(dataset, 'labelBlockLists');
    const labelAllowLists = useInitialListFieldValues(dataset, 'labelAllowLists');
    const allEntities = Object.keys(dataset.datasetGeneratorMetadata);
    const initialValues = useMemo<DatasetFormState>(
        () => createDatasetFormState(dataset, allEntities, labelBlockLists, labelAllowLists),
        [dataset, labelBlockLists, labelAllowLists, allEntities]
    );

    const handleSubmit = useCallback(async (values: DatasetFormState) => {
        await updateDataset(values);
    }, []);

    useEffect(() => {
        fetchDataset(dataset.id);
    }, [dataset.id]);

    return (
        <FilePreviewProvider datasetId={dataset.id}>
            <Form<DatasetFormState>
                mutators={{ ...mutators } as Record<string, Mutator<DatasetFormState>>}
                keepDirtyOnReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={styles.container}>
                            <div className={styles.info}>
                                <DatasetInfo dataset={dataset} />
                            </div>
                            <div className={styles.main}>
                                <GeneratorSetup dataset={dataset} />
                            </div>
                            <div className={styles.meta}>
                                <DatasetMeta datasetName={dataset.name} />
                            </div>
                        </div>
                    </form>
                )}
            </Form>
            <FilePreviewDialog />
        </FilePreviewProvider>
    );
}
