import { StripeInvoiceStatus as StripeInvoiceStatusType } from '@/types';
import {
    QuiBox,
    QuiBrandBackgroundColorToken,
    QuiBrandTextColorToken,
    QuiSemanticBackgroundColorToken,
    QuiSemanticSurfaceColorToken,
    QuiSemanticTextColorToken,
} from '@tonicai/ui-quinine';
import { toTitleCase } from './utils';

type Props = { status: StripeInvoiceStatusType };
export default function StripeInvoiceStatus({ status }: Props) {
    const redBG = 'surface-danger';
    const redFG = 'text-danger';
    const grayBG = 'surface-neutral';
    const grayFG = 'text-base';
    const backgroundColor: Record<string, QuiSemanticBackgroundColorToken | QuiBrandBackgroundColorToken | QuiSemanticSurfaceColorToken> = {
        paid: 'surface-positive',
        open: grayBG,
        draft: grayBG,
        uncollectible: redBG,
        void: redBG,
        deleted: redBG,
    };
    const textColor: Record<string, QuiBrandTextColorToken | QuiSemanticTextColorToken> = {
        paid: 'text-positive',
        open: grayFG,
        draft: grayFG,
        uncollectible: redFG,
        void: redFG,
        deleted: redFG,
    };
    return (
        <QuiBox
            bg={backgroundColor[status]}
            color={textColor[status]}
            textAlign="center"
            borderRadius="md"
            padding="xs md"
            text="text-xs"
            style={{ width: 'min-content' }}
        >
            {toTitleCase(status)}
        </QuiBox>
    );
}
