import { QuiBox, QuiButtonLink, QuiFlexBoxColumn } from '@tonicai/ui-quinine';
import { PageTitle } from '../PageTitle/PageTitle';
import { CurrentUserAvatar } from '../CurrentUserAvatar/CurrentUserAvatar';
import { useAtomValue } from 'jotai';
import { currentUserAtom } from '../../stores/auth';
import { CopyToClipboardButton } from '../CopyToClipboardButton/CopyToClipboardButton';
import { UploadFilesButton } from './UploadFilesButton';
import { RunPipelineButton } from './RunPipelineButton';
import { usePipelineContext } from './usePipelineContext';
import { NextSteps } from './NextSteps';

export function PipelineSidebar() {
    const { parseJobConfig } = usePipelineContext();
    const currentUser = useAtomValue(currentUserAtom);

    return (
        <QuiFlexBoxColumn gap="md">
            <PageTitle
                icon="git-branch"
                to={`/pipelines/${parseJobConfig.id}`}
                title={
                    <QuiFlexBoxColumn gap="xs">
                        <QuiBox overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                            {parseJobConfig.name}
                        </QuiBox>
                        <QuiBox display="flex" alignItems="center" gap="xs">
                            <div>
                                <CurrentUserAvatar size="sm" />
                            </div>
                            {currentUser?.displayName ? (
                                <QuiBox
                                    color="text-subdued"
                                    text="text-xs"
                                    flexGrow="1"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    whiteSpace="nowrap"
                                >{`Created by ${currentUser?.displayName}`}</QuiBox>
                            ) : null}
                        </QuiBox>
                    </QuiFlexBoxColumn>
                }
            >
                <QuiBox justifyContent="space-between" text="mono-text-xs" color="text-subdued" display="flex" gap="sm" alignItems="center">
                    <span data-test="pipeline-config-id">{parseJobConfig.id}</span>
                    <CopyToClipboardButton icon="clipboard" content={parseJobConfig.id} size="xs" />
                </QuiBox>
            </PageTitle>

            <QuiBox display="flex" gap="sm">
                <QuiBox display="flex" flexGrow="1" flexDirection="column">
                    {parseJobConfig.useInternalBucket ? <UploadFilesButton /> : <RunPipelineButton />}
                </QuiBox>
                <QuiButtonLink to={`/pipelines/${parseJobConfig.id}/settings`} iconLeft="settings" />
            </QuiBox>
            <NextSteps />
        </QuiFlexBoxColumn>
    );
}
