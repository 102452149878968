import { AzureCredentials } from './AzureCredentials';
import { QuiBox, QuiForm, QuiSpinner, QuiSwitchField, QuiTextField, useQuiToasts } from '@tonicai/ui-quinine';
import isEqual from 'fast-deep-equal';
import { FORM_ERROR, Mutator } from 'final-form';
import mutators from 'final-form-arrays';
import { useEffect, useState } from 'react';
import { FormSpy } from 'react-final-form';
import { Navigate } from 'react-router-dom';
import { FormSection } from '@/components/FormSection/FormSection';
import { PageTitle } from '@/components/PageTitle/PageTitle';
import { usePipelineContext } from '@/components/PipelineLayout/usePipelineContext';
import { TitleBox } from '@/components/TitleBox/TitleBox';
import { fetchParseJob, updateParseJob } from '@/stores';
import { AwsCredentialSourceEnum, FileSourceStringEnum, ParseJobConfigResponse, UpdateParseJobConfigRequest } from '@/types';
import { isAwsCredentialsError, isAzureCredentialsError } from '@/utils';
import { requiredString } from '@/validation';
import { AWSCredentials } from './AWSCredentials';
import { CancelSubmitButtons } from './CancelSubmitButtons';
import { DatabricksCredentials } from './DatabricksCredentials';
import { DeleteParseButton } from './DeleteParseButton';
import { FileExtensionsField } from './FileExtensionsField';
import styles from './ParseSettings.module.scss';
import { PathPrefixesAndSelectedFilesFields } from './PathPrefixesAndSelectedFilesFields';
import { PathSelectorField } from './PathSelectorField';
import { StringArrayField } from './StringArrayField';

const formMutators: Record<string, Mutator<UpdateParseJobConfigRequest>> = {
    ...mutators,
};

function parseJobConfigResponseToUpdateParseJobConfigRequest(data: ParseJobConfigResponse): UpdateParseJobConfigRequest {
    const {
        name,
        parseJobExternalCredential,
        databricksConnectionDetails,
        selectedFileExtensions,
        selectedFiles,
        pathPrefixes,
        outputPath,
        synthesizeFiles,
    } = data;

    const updateRequest: UpdateParseJobConfigRequest = {
        name,
        parseJobExternalCredential,
        databricksConnectionDetails,
        selectedFileExtensions,
        selectedFiles,
        pathPrefixes,
        outputPath,
        synthesizeFiles,
    };

    return updateRequest;
}

export function ParseSettings() {
    const {
        parseJobConfig: { id: parseJobConfigId },
        refetchParseJobConfig,
    } = usePipelineContext();
    const [parseJobConfig, setParseJobConfig] = useState<null | ParseJobConfigResponse>(null);
    const [initialValues, setInitialValues] = useState<UpdateParseJobConfigRequest | null>(null);
    const [fetchedParseJob, setFetchedParseJob] = useState(false);
    const addToast = useQuiToasts();

    useEffect(() => {
        setFetchedParseJob(false);
        fetchParseJob(parseJobConfigId)
            .then((parseJob) => {
                setParseJobConfig(parseJob);
                setInitialValues(parseJobConfigResponseToUpdateParseJobConfigRequest(parseJob));
                setFetchedParseJob(true);
            })
            .catch(() => {
                setFetchedParseJob(true);
            });
    }, [parseJobConfigId]);

    if (!parseJobConfigId) return <Navigate to="/" />;

    if (!fetchedParseJob) return <QuiSpinner />;

    if (!initialValues || !parseJobConfig) return <Navigate to="/" />;

    const isAwsPipeline = parseJobConfig.fileSource === FileSourceStringEnum.Aws;
    const isDatabricksPipeline = parseJobConfig.fileSource === FileSourceStringEnum.Databricks;
    const isAzurePipeline = parseJobConfig.fileSource === FileSourceStringEnum.Azure;
    const isLocalPipeline = parseJobConfig.useInternalBucket;

    if (!isAwsPipeline && !isDatabricksPipeline && !isAzurePipeline) throw new Error('Unsupported file source');

    const fileSystemType = (() => {
        switch (parseJobConfig.fileSource) {
            case FileSourceStringEnum.Aws:
                return 'aws';
            case FileSourceStringEnum.Databricks:
                return 'databricks';
            case FileSourceStringEnum.Azure:
                return 'azure';
            default:
                throw new Error('Unsupported file source');
        }
    })();

    return (
        <QuiForm<UpdateParseJobConfigRequest>
            mutators={formMutators}
            initialValues={initialValues}
            onSubmit={async (values, form) => {
                try {
                    const newValues = {
                        ...values,
                        pathPrefixes: Array.isArray(values?.pathPrefixes) ? values.pathPrefixes : [],
                        selectedFiles: Array.isArray(values?.selectedFiles) ? values.selectedFiles : [],
                    };

                    delete newValues['parseJobExternalCredential'];

                    await updateParseJob(parseJobConfigId, newValues)
                        .then((updatedParseJob) => {
                            addToast({
                                title: 'Pipeline updated',
                                variant: 'positive',
                            });
                            refetchParseJobConfig();
                            form.reset(parseJobConfigResponseToUpdateParseJobConfigRequest(updatedParseJob));
                            return undefined;
                        })
                        .catch((error) => {
                            console.error(error);

                            if (isAwsCredentialsError(error)) {
                                if (
                                    parseJobConfig.useInternalBucket ||
                                    parseJobConfig.awsCredentialSource === AwsCredentialSourceEnum.FromEnvironment
                                ) {
                                    addToast({
                                        title: 'Unable to update pipeline due to invalid environment AWS credentials',
                                        variant: 'danger',
                                    });
                                    return {
                                        [FORM_ERROR]: 'Invalid environment AWS credentials',
                                    };
                                } else {
                                    addToast({
                                        title: 'Unable to update pipeline due to invalid AWS credentials',
                                        variant: 'danger',
                                    });
                                    return {
                                        [FORM_ERROR]: 'Invalid AWS credentials',
                                    };
                                }
                            }

                            if (isAzureCredentialsError(error)) {
                                addToast({
                                    title: 'Unable to update pipeline due to invalid Azure credentials',
                                    variant: 'danger',
                                });
                                return {
                                    [FORM_ERROR]: 'Invalid Azure credentials',
                                };
                            }

                            addToast({
                                title: 'Unable to update pipeline',
                                variant: 'danger',
                            });
                            return {
                                [FORM_ERROR]: error.message || 'An error occurred',
                            };
                        });

                    return undefined;
                } catch (error) {
                    console.error(error);
                    return { [FORM_ERROR]: 'An error occurred' };
                }
            }}
        >
            <QuiBox display="flex" flexDirection="column" gap="sm">
                <QuiBox display="flex" flexDirection="column" gap="xl">
                    <FormSpy subscription={{ values: true, initialValues: true }}>
                        {({ values, initialValues }) => (
                            <QuiBox
                                bg="background-alt"
                                padding="md"
                                className={styles.formHeader}
                                display="flex"
                                justifyContent="space-between"
                                gap="md"
                                borderBottom="stroke-base"
                            >
                                <QuiBox display="flex" flexDirection="column" gap="md">
                                    <PageTitle icon="settings" title="Pipeline Settings" />
                                </QuiBox>

                                <QuiBox display="flex" gap="md" alignItems="center">
                                    {!isEqual(values, initialValues) ? (
                                        <QuiBox style={{ fontStyle: 'italic' }} display="inline-flex" color="text-subdued" text="text-xs">
                                            You have unsaved changes
                                        </QuiBox>
                                    ) : null}
                                    <DeleteParseButton parseJobConfigId={parseJobConfigId} name={parseJobConfig.name} />
                                    <CancelSubmitButtons />
                                </QuiBox>
                            </QuiBox>
                        )}
                    </FormSpy>

                    <QuiBox display="flex" flexDirection="column" gap="xl" padding="md">
                        <FormSection title="Details" description="Provide a name for your pipeline.">
                            <QuiTextField validate={requiredString} name="name" label="Name" id="parse-name" />
                        </FormSection>

                        {isDatabricksPipeline ? (
                            <FormSection title="Connect to files" description="Enter your Databricks credentials to connect to your data source.">
                                <DatabricksCredentials />
                                <PathSelectorField parseJobConfigId={parseJobConfigId} fileSystemType={fileSystemType} />
                            </FormSection>
                        ) : isAwsPipeline && !isLocalPipeline ? (
                            <FormSection title="Connect to files" description="Enter your AWS credentials to connect to your data source.">
                                {parseJobConfig.awsCredentialSource === AwsCredentialSourceEnum.UserProvided && <AWSCredentials />}
                                {/*Select Output Location*/}
                                <PathSelectorField parseJobConfigId={parseJobConfigId} fileSystemType={fileSystemType} />
                            </FormSection>
                        ) : isAzurePipeline ? (
                            <FormSection title="Connect to files" description="Enter your Azure credentials to connect to your data source.">
                                <AzureCredentials />
                                <PathSelectorField parseJobConfigId={parseJobConfigId} fileSystemType={fileSystemType} />
                            </FormSection>
                        ) : null}

                        {isAwsPipeline || isDatabricksPipeline || isAzurePipeline ? (
                            <FormSection
                                title="File processing"
                                description="Pipelines can consist of one of the following types: DocX, XLXS, PDF, PNG, TIFF, JPEG, TXT, CSV, TSV, EML, MSG. You may select individual files or folders of the same type."
                            >
                                <QuiBox padding="md" border="stroke-base" borderRadius="md" bg="background-base">
                                    <QuiSwitchField
                                        name="synthesizeFiles"
                                        label="Synthesize Files"
                                        subHelperText="Create redacted/synthesized copies of files in their original formats"
                                    />
                                </QuiBox>

                                {!isLocalPipeline && (
                                    <TitleBox title="File Processing Settings">
                                        <FileExtensionsField name="selectedFileExtensions" />
                                    </TitleBox>
                                )}

                                {/*Select folders and files to add to run*/}
                                {!isLocalPipeline && (
                                    <PathPrefixesAndSelectedFilesFields parseJobConfigId={parseJobConfigId} fileSystemType={fileSystemType} />
                                )}

                                {!isLocalPipeline && (
                                    <StringArrayField
                                        disableTextFields
                                        title="Prefix Patterns"
                                        name="pathPrefixes"
                                        noItemsFoundText="No prefix patterns added. Use the file explorer to select buckets and/or folders to configure where we look for files to process."
                                    />
                                )}

                                {!isLocalPipeline && (
                                    <StringArrayField
                                        disableTextFields
                                        title="Selected Files"
                                        name="selectedFiles"
                                        noItemsFoundText="No files added. Use the file explorer below to files that match the pattern."
                                    />
                                )}
                            </FormSection>
                        ) : null}
                    </QuiBox>
                </QuiBox>
            </QuiBox>
        </QuiForm>
    );
}
