import { CodeBox } from '@/components/CodeBlock/CodeBox';
import { QuiBox, QuiFlexBoxColumn, QuiIconEnum, QuiLink } from '@tonicai/ui-quinine';
import { SDK_DOCUMENTATION_URL } from '@/constants';
import Notice from '../Notice/Notice';

export function RequestExplorerCodeSnippet() {
    const code = `from tonic_textual.redact_api import TextualNer
from tonic_textual.classes.record_api_request_options import RecordApiRequestOptions

ner = TextualNer()
record_options = RecordApiRequestOptions(record=True, retention_time_in_hours=1, tags=["my_first_tag"])
redacted_text = ner.redact("Hello, my name is John Doe", record_options=record_options)`;

    const url = SDK_DOCUMENTATION_URL + 'redact/redacting_text.html#recording-api-requests';

    return (
        <QuiFlexBoxColumn style={{ marginTop: 25 }} gap="lg">
            <Notice variant="purple">
                <Notice.Title variant="purple" icon={QuiIconEnum.FastForward}>
                    You can record API requests made to the Textual SDK
                </Notice.Title>
                <Notice.Text>
                    To make an API request request visible in this list, provide <strong>record_options</strong> as an argument to your{' '}
                    <strong>redact</strong> call. The below example retains the request for 1 hour before it is automatically deleted.
                </Notice.Text>
                <QuiLink external={true} variant="currentcolor" size="text-sm" to={url} iconRight="external-link">
                    View Documentation
                </QuiLink>
            </Notice>
            <QuiBox display={'flex'} flexDirection={'column'} gap={'lg'}>
                <CodeBox expandable={true} title="Example" code={code} language="python" />
            </QuiBox>
        </QuiFlexBoxColumn>
    );
}
