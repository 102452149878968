import {
    QuiBox,
    QuiButton,
    QuiIconEnum,
    QuiModalContent,
    QuiModalDialog,
    QuiModalFooter,
    QuiSelect,
    QuiText,
    useQuiModal,
} from '@tonicai/ui-quinine';
import { Tabs } from '@/components/Tabs/Tabs';
import { fetchFilesNeedingRescan, usePIITypes } from '@/stores';
import { PiiTypeEnum } from '@/types';
import { useState } from 'react';
import { useForm } from 'react-final-form';
import { RegexListField } from './RegexListField';
import { PiiTypeToLabel } from './utils';
import { useDatasetContext } from '@/contexts/DatasetContext';

type RegexListFieldModalProps = Readonly<{
    buttonIcon?: QuiIconEnum;
    buttonText?: string;
    initialEntityType?: string;
    listType: string;
}>;

export function RegexListFieldModal({ buttonIcon, buttonText, initialEntityType, listType }: RegexListFieldModalProps) {
    const modal = useQuiModal();
    const { dataset } = useDatasetContext();
    const form = useForm();
    const [entityType, setEntityType] = useState(initialEntityType);

    const isListEmpty = initialEntityType ? !form.getState().values[listType][initialEntityType]?.regexes.filter(Boolean).length : true;
    const piiTypes = usePIITypes()
        .map((piiType) => ({
            value: piiType,
            label: PiiTypeToLabel[PiiTypeEnum[piiType]].label,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const closeModal = () => {
        fetchFilesNeedingRescan(dataset.id);
        setEntityType(initialEntityType);
        modal.close();
    };

    return (
        <>
            <QuiButton
                iconRight={buttonIcon}
                size="sm"
                variant={isListEmpty ? 'outline-default' : 'positive'}
                title={listType === 'labelAllowLists' ? 'Add entities to detection' : 'Exclude entities from detection'}
                onClick={modal.toggle}
            >
                {buttonText}
            </QuiButton>

            <QuiModalDialog
                disableDismissOnEscapeKeyDown={false}
                isOpen={modal.isOpen}
                onClose={closeModal}
                title={<QuiText size="text-lg">Custom Entity Detection</QuiText>}
            >
                <QuiModalContent>
                    <QuiBox display={'flex'} flexDirection={'column'} gap={'md'}>
                        <QuiBox style={{ width: '250px' }}>
                            <QuiSelect
                                isClearable={false}
                                placeholder=""
                                value={piiTypes.find((piiType) => piiType.value === entityType)}
                                onChange={(option) => {
                                    if (option) {
                                        setEntityType(option.value);
                                    }
                                }}
                                options={piiTypes}
                            />
                        </QuiBox>

                        <Tabs.Container defaultTab={listType}>
                            <Tabs.TabTriggers>
                                <Tabs.TabTrigger icon="plus-circle" id="labelAllowLists">
                                    Add to detection
                                </Tabs.TabTrigger>
                                <Tabs.TabTrigger icon="slash" id="labelBlockLists">
                                    Exclude from detection
                                </Tabs.TabTrigger>
                            </Tabs.TabTriggers>
                            <Tabs.TabContent id="labelAllowLists">
                                <RegexListField entityType={entityType} listType="labelAllowLists" />
                            </Tabs.TabContent>
                            <Tabs.TabContent id="labelBlockLists">
                                <RegexListField entityType={entityType} listType="labelBlockLists" />
                            </Tabs.TabContent>
                        </Tabs.Container>
                    </QuiBox>
                </QuiModalContent>
                <QuiModalFooter>
                    <QuiBox display={'flex'} justifyContent={'end'} className={'qui-w-100'} gap={'sm'}>
                        <QuiButton type="button" onClick={closeModal}>
                            Cancel
                        </QuiButton>
                        <QuiButton
                            type="button"
                            disabled={!entityType}
                            onClick={() => {
                                form.change('shouldRescan', false);
                                return form.submit()?.then(() => closeModal());
                            }}
                        >
                            Save Without Scanning Files
                        </QuiButton>
                        <QuiButton
                            type="button"
                            variant="brand-purple"
                            disabled={!entityType}
                            onClick={() => {
                                form.change('shouldRescan', true);
                                return form.submit()?.then(() => closeModal());
                            }}
                        >
                            Save and Scan Files
                        </QuiButton>
                    </QuiBox>
                </QuiModalFooter>
            </QuiModalDialog>
        </>
    );
}
