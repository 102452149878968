import { singleRequestFetchStatusAtom } from '@/stores/api_request';
import { QuiBox, QuiIcon, QuiSpinner, QuiText } from '@tonicai/ui-quinine';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DetectTextEntitiesSingleModel } from '@/types';
import styles from './RequestExplorerUnifiedView.module.scss';
import { nanoid } from 'nanoid';
import { RenderDeidentifyResultsToHtmlRequestMessage, RenderDeidentifyResultsToHtmlResponseMessage } from '@/workers/redactedSchema';
import { useAtomValue } from 'jotai';
import classNames from 'classnames';

type RedactedProps = Readonly<{
    text?: string;
    entities: DetectTextEntitiesSingleModel[];
    title?: string;
    titleClassName?: string;
}>;

export function RequestExplorerUnifiedView({ text, entities, title, titleClassName }: RedactedProps) {
    const isMountedRef = useRef(false);
    const id = useMemo(() => nanoid(), []);
    const workerRef = useRef<Worker | null>(null);
    const fetchStatus = useAtomValue(singleRequestFetchStatusAtom);
    const [html, setHtml] = useState<string>();
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        workerRef.current = new Worker(new URL('./../../workers/redactedWorker.ts', import.meta.url), {
            type: 'module',
        });

        workerRef.current.onmessage = async function messageHandler(e) {
            try {
                const result: RenderDeidentifyResultsToHtmlResponseMessage = JSON.parse(e.data);

                if (result.id !== id) return;

                if (!isMountedRef.current) return;

                setHtml(result.html);
            } catch (error) {
                console.error('error', error);
            }
        };

        return () => {
            workerRef.current?.terminate();
        };
    }, [id]);

    useEffect(() => {
        if (!workerRef.current) return;

        try {
            const message: RenderDeidentifyResultsToHtmlRequestMessage = {
                text: text,
                entities: entities,
                id,
                redactedTextClassName: styles.redactedText,
                redactedTextLabelClassName: styles.redactedTextLabel,
            };

            const messageString = JSON.stringify(message);

            workerRef.current.postMessage(messageString);
        } catch (e) {
            console.error(e);
        }
    }, [text, entities, id]);

    if (fetchStatus === 'loading') {
        return (
            <QuiBox display={'flex'} justifyContent={'center'}>
                <QuiSpinner />
            </QuiBox>
        );
    }
    if (fetchStatus === 'error') {
        return (
            <QuiBox display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <QuiIcon icon={'alert-triangle'} style={{ width: 50, height: 50 }} color={'text-danger'} />
                <QuiText size={'text-xl'} color={'text-danger'}>
                    Something went wrong. Please try again.
                </QuiText>
            </QuiBox>
        );
    }

    if (html) {
        if (title != null) {
            return (
                <QuiBox style={{ width: '100%' }} className={styles.modifiedDiffBox} display={'flex'} flexDirection={'column'} gap={'md'}>
                    <QuiBox
                        bg={'background-base'}
                        display={'flex'}
                        style={{ width: '100%', borderRadius: 0, borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                        border={'stroke-base'}
                        padding={'xs'}
                    >
                        <QuiText className={classNames(titleClassName)} weight={'bold'} size={'text-lg'}>
                            {title}
                        </QuiText>
                    </QuiBox>
                    <div className={classNames(styles.preview, 'qui-padding-xs')} dangerouslySetInnerHTML={{ __html: html }} />
                </QuiBox>
            );
        }
        return (
            <QuiBox padding={'xs'} style={{ height: '100%' }}>
                <div style={{ height: '100%' }} className={styles.preview} dangerouslySetInnerHTML={{ __html: html }} />
            </QuiBox>
        );
    }

    return (
        <QuiBox padding="md">
            <QuiSpinner size="sm" />
        </QuiBox>
    );
}
