import { QuiBadge, QuiBox, QuiButton } from '@tonicai/ui-quinine';
import { useScale } from '../Templates/useScale';
import styles from './ZoomButtons.module.scss';
import classNames from 'classnames';

type ZoomButtonsProps = Readonly<
    ReturnType<typeof useScale> & {
        className?: string;
    }
>;

export function ZoomButtons({ className, canZoomIn, canZoomOut, zoomIn, zoomOut, stringScale }: ZoomButtonsProps) {
    const cn = classNames('qui-shadow-sm', className);
    return (
        <QuiBox
            className={cn}
            justifyContent="space-between"
            padding="xs"
            borderRadius="lg"
            border="stroke-base"
            bg="background-brand-black-900"
            display="flex"
            gap="xs"
        >
            <QuiButton variant="brand-black" disabled={!canZoomOut} size="xs" iconLeft="minus" type="button" onClick={zoomOut} />
            <QuiBadge className={styles.stringScale} size="xs" variant="neutral-filled">
                {stringScale}
            </QuiBadge>
            <QuiButton variant="brand-black" disabled={!canZoomIn} size="xs" iconLeft="plus" type="button" onClick={zoomIn} />
        </QuiBox>
    );
}
