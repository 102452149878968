import { QuiBox, QuiIcon, QuiIconEnum } from '@tonicai/ui-quinine';
import React from 'react';

type Variants = 'purple' | 'royal';
type Props = {
    variant: Variants;
    children?: React.ReactNode;
};
function Notice({ variant, children }: Props) {
    return (
        <QuiBox
            bg={`background-brand-${variant}-50`}
            borderRadius="lg"
            border={`stroke-brand-${variant}-200`}
            display={'flex'}
            gap={'md'}
            flexDirection={'column'}
            justifyContent={'center'}
            padding={'md'}
        >
            {children}
        </QuiBox>
    );
}

type TitleProps = {
    variant?: Variants;
    icon?: QuiIconEnum;
    children: React.ReactNode;
};
function Title({ variant, icon, children }: TitleProps) {
    return (
        <QuiBox alignItems="center" gap="sm" display="flex" text="text-md" weight="medium">
            {icon && <QuiIcon color={variant && `text-brand-${variant}-600`} icon={icon} />}
            {children}
        </QuiBox>
    );
}
Notice.Title = Title;

type TextProps = {
    children: React.ReactNode;
};
function Text({ children }: TextProps) {
    return <QuiBox text="text-sm">{children}</QuiBox>;
}
Notice.Text = Text;

export default Notice;
