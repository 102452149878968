import { ForgotPasswordRequest } from '@/types';
import { MainLayoutSlim, SlimLayoutCard } from '../../components/Layout/Layout';
import { ForgotPasswordFormBody } from './components/ForgotPasswordFormBody';
import { QuiForm } from '@tonicai/ui-quinine';
import { useSearchParams } from 'react-router-dom';
import { forgotPassword } from '../../stores/auth';
import { FORM_ERROR } from 'final-form';

export function ForgotPasswordPage() {
    const [searchParams] = useSearchParams();

    //read token from session storage
    const tokenFromSession = sessionStorage.getItem('forgot_password_token');
    const tokenFromQuery = searchParams.get('token');

    let tokenForForm: string | null = null;
    if (tokenFromSession && tokenFromQuery && tokenFromSession === tokenFromQuery) {
        tokenForForm = tokenFromSession;
    }

    const isRetry = searchParams.get('retry') === '1';

    const submitForgotPassword = async (values: ForgotPasswordRequest) => {
        try {
            await forgotPassword(values);
        } catch {
            return {
                [FORM_ERROR]: 'Failed to request password reset. Please try again later.',
            };
        }
    };

    return (
        <MainLayoutSlim widthRem={20}>
            <SlimLayoutCard>
                <QuiForm<ForgotPasswordRequest>
                    onSubmit={submitForgotPassword}
                    initialValues={
                        {
                            token: tokenForForm,
                            isRetry,
                        } as ForgotPasswordRequest
                    }
                >
                    <ForgotPasswordFormBody />
                </QuiForm>
            </SlimLayoutCard>
        </MainLayoutSlim>
    );
}
