import { QuiBadge, QuiBox, QuiFlexBoxColumn, QuiLink, QuiTooltip } from '@tonicai/ui-quinine';
import { CreateMenu } from '../../components/CreateMenu/CreateMenu';

export function Welcome() {
    return (
        <QuiFlexBoxColumn gap="lg" padding="md">
            <QuiFlexBoxColumn gap="sm">
                <QuiBadge variant="brand-purple" iconLeft="home" />

                <QuiBox text="display-xs" weight="bold">
                    👋 Welcome!
                </QuiBox>
                <QuiBox text="text-md" color="text-subdued">
                    Textual gets your unstructured data ready-to-use for RAG or LLM development.
                </QuiBox>
                <QuiTooltip content={'Tonic Textual supports plain text, csv. PDF, image formats (png, jpg, tif), xlsx and docx files'}>
                    <QuiLink
                        variant="currentcolor"
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            e.preventDefault();
                        }}
                    >
                        Supported file types
                    </QuiLink>
                </QuiTooltip>
            </QuiFlexBoxColumn>

            <CreateMenu />
        </QuiFlexBoxColumn>
    );
}
