import { QuiBox, QuiButtonLink, QuiFlexBoxColumn } from '@tonicai/ui-quinine';
import { Table, TableCellProps, TableColumnConfig } from '../../components/Table/Table';
import { PlainLink } from '../../components/PlainLink/PlainLink';
import { JobStatusTag } from '../../components/JobStatusTag/JobStatusTag';
import { EndpointGuardComponentProps } from '../../components/EndpointGuard/EndpointGuard';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFileParseJobContext } from './useFileParseJobContext';
import { FILE_TYPES_THAT_CAN_BE_SYNTHESIZED, FileParseResultApiModel } from '../../types';
import { usePipelineContext } from '../../components/PipelineLayout/usePipelineContext';
import { fetchUsage } from '@/stores';

function Filename({ data: { file, id, fileParseJobId, fileStatus, parsedFilePath } }: TableCellProps<FileParseResultApiModel>): JSX.Element {
    const params = useParams<{ parseJobConfigId: string }>();
    const parseJobConfigId = params.parseJobConfigId!;
    const { parseJobConfig } = usePipelineContext();

    if (fileStatus === 'Failed') {
        return (
            <QuiFlexBoxColumn>
                <QuiBox data-test="pipeline-run-file-name" text="text-sm">
                    {file.fileName}
                </QuiBox>
                {!parseJobConfig.useInternalBucket && parsedFilePath && parsedFilePath.trim().length > 0 ? (
                    <QuiBox text="text-xs" color="text-subdued">
                        {parsedFilePath}
                    </QuiBox>
                ) : null}
            </QuiFlexBoxColumn>
        );
    }

    return (
        <PlainLink to={`/pipelines/${parseJobConfigId}/runs/${fileParseJobId}/files/${id}`}>
            <QuiFlexBoxColumn>
                <QuiBox data-test="pipeline-run-file-name" text="text-sm">
                    {file.fileName}
                </QuiBox>
                {!parseJobConfig.useInternalBucket && parsedFilePath ? (
                    <QuiBox text="text-xs" color="text-subdued">
                        {parsedFilePath}
                    </QuiBox>
                ) : null}
            </QuiFlexBoxColumn>
        </PlainLink>
    );
}

function ViewResultsLink({ data: { id, fileParseJobId, fileStatus } }: TableCellProps<FileParseResultApiModel>): JSX.Element | null {
    const params = useParams<{ parseJobConfigId: string }>();
    const parseJobConfigId = params.parseJobConfigId!;

    if (fileStatus === 'Failed' || fileStatus === 'Queued') {
        return null;
    }

    return (
        <QuiBox display="flex" justifyContent="end">
            <QuiButtonLink size="sm" to={`/pipelines/${parseJobConfigId}/runs/${fileParseJobId}/files/${id}`} iconRight="chevron-right" />
        </QuiBox>
    );
}

function FileStatus({ data: { fileStatus, file } }: TableCellProps<FileParseResultApiModel>): JSX.Element {
    const status =
        !FILE_TYPES_THAT_CAN_BE_SYNTHESIZED.includes(file.fileType) && fileStatus === 'File Synthesis Only' ? 'Synthesis Not Supported' : fileStatus;

    return <JobStatusTag data-test="pipeline-run-file-status" size="sm" status={status} />;
}

const fileTableColumns: TableColumnConfig<FileParseResultApiModel>[] = [
    {
        id: 'filename',
        label: 'Filename',
        Component: Filename,
    },
    {
        id: 'fileStatus',
        label: '',
        Component: FileStatus,
    },
    {
        id: 'viewResults',
        label: '',
        Component: ViewResultsLink,
    },
];

export function FilesTable({ data, fetchData }: EndpointGuardComponentProps<{ files: FileParseResultApiModel[] }>) {
    const { parseJobConfigId } = usePipelineContext();
    const fileParseJob = useFileParseJobContext();

    const deDuplicatedData = useMemo(() => {
        const files: FileParseResultApiModel[] = [];

        data.files.forEach((file) => {
            if (files.find((f) => f.parsedFilePath === file.parsedFilePath)) return;

            files.push(file);
        });

        return files;
    }, [data]);

    useEffect(() => {
        if (fileParseJob.status === 'Completed' || fileParseJob.status === 'Failed') {
            fetchData();
            fetchUsage();
        }
    }, [fileParseJob, fetchData]);

    useEffect(() => {
        if (fileParseJob.status === 'Running') return;

        const interval = window.setInterval(() => {
            fetchData();
        }, 2000);

        return () => {
            window.clearTimeout(interval);
        };
    }, [fetchData, parseJobConfigId, fileParseJob]);

    return (
        <Table<FileParseResultApiModel>
            data-test="pipeline-files-table"
            noDataMessage="No files found"
            data={deDuplicatedData}
            columns={fileTableColumns}
        />
    );
}
