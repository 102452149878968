import { QuiButton } from '@tonicai/ui-quinine';
import { openPlanDialog } from '@/stores';

type UpgradePlanButtonProps = Readonly<{
    onClick?: () => void;
}>;

export function UpgradePlanButton({ onClick }: UpgradePlanButtonProps) {
    return (
        <QuiButton
            variant="positive"
            onClick={() => {
                onClick?.();
                openPlanDialog();
            }}
            type="button"
        >
            Upgrade Plan
        </QuiButton>
    );
}
