import { FileAdder } from '@/components/FileAdder/FileAdder';
import { useFilePreviewContext } from '@/components/FilePreviews/useFilePreviewContext';
import { DatasetTemplatesButton } from '@/components/Templates/DatasetTemplatesButton';
import { usePolling } from '@/hooks/usePolling';
import { client } from '@/services/HTTPClient';
import { fetchFilesNeedingRescan, useFilesNeedingRescan } from '@/stores';
import { currentUserAtom } from '@/stores/auth';
import { useIsOcrEnabled } from '@/stores/settings';
import { Dataset } from '@/types';
import { QuiBadge, QuiBox, QuiButton, QuiIcon, QuiIconEnum, QuiText, useQuiModal } from '@tonicai/ui-quinine';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import { useForm } from 'react-final-form';
import styles from './DatasetInfo.module.scss';
import DatasetSettingsModal from './DatasetSettingsModal';

type DatasetInfoProps = Readonly<{
    dataset: Dataset;
}>;

export function DatasetInfo({ dataset }: DatasetInfoProps) {
    const datasetSettingsModal = useQuiModal();
    const { openPreview } = useFilePreviewContext();
    const user = useAtomValue(currentUserAtom);
    const ocrEnabled = useIsOcrEnabled();
    const [isScanRunning, setIsScanRunning] = useState(false);
    const form = useForm();
    const filesNeedingRescan = useFilesNeedingRescan(dataset.id);

    const hasAtLeastOnePDF = useMemo(() => {
        return dataset.files.find((f) => f.fileType === 'Pdf') !== undefined;
    }, [dataset.files]);

    const hasAnyFiles = useMemo(() => dataset.files.length > 0, [dataset.files]);
    const canRescanFiles = !isScanRunning && hasAnyFiles && !!filesNeedingRescan?.length;

    usePolling(
        async () => {
            const response = await client.get(`/api/dataset/${dataset.id}/jobs/is_running`);
            setIsScanRunning(!!response.data?.isRunning);
            await fetchFilesNeedingRescan(dataset.id);
        },
        true,
        1000
    );

    return (
        <>
            <QuiBox overflow="hidden" borderRadius="md" border="stroke-base">
                <QuiBox bg="background-base" borderBottom="stroke-base" padding="md" gap="md" flexDirection="column" display="flex">
                    <QuiBox display="flex" alignItems="center" gap="sm">
                        <QuiBadge iconLeft="message-square" size="xl" variant="brand-purple" />
                        <QuiText size="text-xl" weight="bold">
                            {dataset.name}
                        </QuiText>
                    </QuiBox>
                    <QuiBox display="flex" flexDirection="column" gap="sm">
                        <div>
                            <QuiBox text="text-xs" color="text-base">
                                {`Edited ${dayjs(dataset.lastUpdated).fromNow()} by ${user?.displayName ?? user?.userName}`}
                            </QuiBox>
                        </div>
                    </QuiBox>
                    {ocrEnabled && hasAtLeastOnePDF && (
                        <QuiBox>
                            <DatasetTemplatesButton datasetId={dataset.id} />
                        </QuiBox>
                    )}
                    <QuiBox display="flex" flexDirection={'column'} gap="sm">
                        <QuiBox display="flex" gap="sm">
                            <QuiButton data-test={'dataset-settings-button'} size="sm" iconLeft="settings" onClick={datasetSettingsModal.open}>
                                Settings
                            </QuiButton>
                        </QuiBox>
                    </QuiBox>
                </QuiBox>

                <QuiBox display="flex" gap="sm" flexDirection="column">
                    <QuiBox display="flex" flexDirection="column" gap="sm" padding="sm md none md">
                        {canRescanFiles && (
                            <QuiBox bg="surface-warning" padding="sm" text="text-sm" display="flex" alignItems="start" gap="sm">
                                <div>
                                    <QuiIcon size="md" color="text-warning" icon={QuiIconEnum.Info} />
                                </div>
                                <QuiBox>
                                    Scan files to apply changes.
                                    <QuiBox text="text-xs" color="text-brand-black-600">
                                        A file scan is required to apply recent changes to your files.
                                    </QuiBox>
                                    <QuiButton
                                        onClick={() => {
                                            form.change('shouldRescan', true);
                                            form.submit()?.then(() => setIsScanRunning(true));
                                        }}
                                    >
                                        Scan
                                    </QuiButton>
                                </QuiBox>
                            </QuiBox>
                        )}

                        <QuiBox display="flex" alignItems="stretch">
                            <QuiButton className={styles.fullWidth} iconLeft="folder" type="button" onClick={openPreview}>
                                Preview and Manage Files
                            </QuiButton>
                        </QuiBox>
                    </QuiBox>
                    <FileAdder mode="combobox" name="fileIds" datasetId={dataset.id} />
                </QuiBox>
            </QuiBox>
            <DatasetSettingsModal isOpen={datasetSettingsModal.isOpen} onClose={datasetSettingsModal.close} />
        </>
    );
}
