import { useParams } from 'react-router-dom';
import { QuiBadge, QuiBox, QuiButton, QuiButtonExternalLink, QuiFlexBoxColumn, QuiSpinner, QuiTooltip, useQuiToasts } from '@tonicai/ui-quinine';
import { FileParseResultApiModel, BaseDocument, FILE_TYPES_THAT_CAN_BE_SYNTHESIZED } from '../../types';
import { EndpointGuard, EndpointGuardComponentProps } from '../../components/EndpointGuard/EndpointGuard';
import { useCallback, useEffect } from 'react';
import { client } from '../../services/HTTPClient';
import { FileTabs } from './FileTabs';
import { Message } from '../../components/Message/Message';
import { useAtomValue } from 'jotai';
import { authResponseAtom } from '../../stores/auth';
import { usePipelineContext } from '../../components/PipelineLayout/usePipelineContext';

export function FileLayout({ data: fileParseResult, fetchData: fetchFileParseResult }: EndpointGuardComponentProps<FileParseResultApiModel>) {
    const params = useParams<{
        fileParseJobId: string;
        fileParseResultId: string;
    }>();
    const fileParseResultId = params.fileParseResultId!;
    const { parseJobConfig } = usePipelineContext();
    const addToast = useQuiToasts();
    const auth = useAtomValue(authResponseAtom);

    useEffect(() => {
        // Bail early if the file is not queued or running
        if (fileParseResult.fileStatus !== 'Queued' && fileParseResult.fileStatus !== 'Running') return;

        // Refetch the file parse result every 3 seconds if it is currently queued or running
        const timeout = setTimeout(() => {
            fetchFileParseResult();
        }, 3000);

        return () => {
            clearTimeout(timeout);
        };
    }, [fileParseResult, fetchFileParseResult]);

    const renderTabs = useCallback(
        (props: EndpointGuardComponentProps<BaseDocument>) => {
            return <FileTabs {...props} fileParseResult={fileParseResult} />;
        },
        [fileParseResult]
    );
    return (
        <QuiFlexBoxColumn gap="lg">
            <QuiBox alignItems="center" display="flex" overflow="hidden" justifyContent="space-between" gap="sm">
                <QuiBox gap="sm" display="flex" flexWrap="nowrap" flexGrow="1" overflow="hidden">
                    <QuiBadge size="lg" iconLeft="file-text" variant="brand-purple" />
                    <QuiBox overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" flexGrow="1">
                        {fileParseResult.file.fileName}
                        {parseJobConfig.useInternalBucket ? null : (
                            <QuiBox whiteSpace="nowrap" text="text-xs" color="text-base--disabled" textOverflow="ellipsis" overflow="hidden">
                                {fileParseResult.parsedFilePath}
                            </QuiBox>
                        )}
                    </QuiBox>
                </QuiBox>

                {parseJobConfig.synthesizeFiles ? (
                    <QuiTooltip
                        content={`Synthesis not supported for ${fileParseResult.file.fileType} files`}
                        disabled={FILE_TYPES_THAT_CAN_BE_SYNTHESIZED.includes(fileParseResult.file.fileType)}
                    >
                        <QuiButtonExternalLink
                            href={`/api/parsejobconfig/${parseJobConfig.id}/jobs/${fileParseResult.fileParseJobId}/files/${fileParseResult.id}/redacted/download?access_token=${auth!.jwt}`}
                            download
                            type="button"
                            iconLeft="download"
                            size="sm"
                            disabled={!FILE_TYPES_THAT_CAN_BE_SYNTHESIZED.includes(fileParseResult.file.fileType)}
                        >
                            Download Synthesized File
                        </QuiButtonExternalLink>
                    </QuiTooltip>
                ) : null}

                <QuiButton
                    size="sm"
                    iconLeft="download"
                    onClick={() => {
                        client
                            .get(`/api/parsejob/files/${fileParseResultId}/content`)
                            .then(({ data }) => {
                                const bytes = new TextEncoder().encode(JSON.stringify(data, null, 2));
                                const blob = new Blob([bytes], {
                                    type: 'application/json;charset=utf-8',
                                });
                                const fileURL = window.URL.createObjectURL(blob);
                                const link = document.createElement('a');
                                link.setAttribute('download', fileParseResult.file.fileName.replace('.', '_') + '.json');
                                link.href = fileURL;
                                document.body.appendChild(link);
                                link.click();
                                link.remove();
                            })
                            .catch(() => {
                                addToast({
                                    title: 'Unable to download file',
                                    variant: 'danger',
                                });
                            });
                    }}
                >
                    Download Results
                </QuiButton>
            </QuiBox>

            {/* if the file processing was cancelled */}
            {fileParseResult.fileStatus === 'Canceled' ? <Message variant="warning">File processing was canceled</Message> : null}

            {/* if the file is queued */}
            {fileParseResult.fileStatus === 'Queued' ? <Message variant="info">File is queued for processing</Message> : null}

            {/* if the file is running */}
            {fileParseResult.fileStatus === 'Running' ? (
                <Message variant="info">
                    <QuiSpinner size="sm" /> File is processing
                </Message>
            ) : null}

            {/* Only show the preview if the file has been processed successfully */}
            {fileParseResult.fileStatus === 'Completed' ||
            fileParseResult.fileStatus === 'Skipped' ||
            fileParseResult.fileStatus == 'File Synthesis Only' ? (
                <EndpointGuard<BaseDocument>
                    endpoint={`/api/parsejob/files/${fileParseResultId}/content/filtered`}
                    errorMessage="Error loading file preview"
                    notFoundMessage="File not found"
                    render={renderTabs}
                />
            ) : null}

            {/* If the file failed to process, show an error message */}
            {fileParseResult.fileStatus === 'Failed' ? <Message variant="error">Failed to process file</Message> : null}
        </QuiFlexBoxColumn>
    );
}
