import { QuiBadge, QuiBox, QuiForm, QuiSwitchField, QuiText } from '@tonicai/ui-quinine';
import { SettingsSection, SettingsSectionContent, SettingsSectionInfo } from './SettingsSection';

export function DatasetSettings() {
    return (
        <QuiForm<unknown>
            onSubmit={() => {
                return undefined;
            }}
        >
            <QuiBox maxWidth="tablet" display="flex" flexDirection="column" gap="lg">
                <QuiBox display="flex" gap="md" alignItems="center">
                    <QuiBadge iconLeft="settings" size="lg" variant="brand-purple" />
                    <QuiText as="h1" size="display-sm" weight="medium">
                        Dataset Settings
                    </QuiText>
                </QuiBox>
                <SettingsSection>
                    <SettingsSectionInfo title="Settings" description="Provide a name and storage location for adding new files to Textual." />
                    <SettingsSectionContent>
                        <p>controls</p>
                    </SettingsSectionContent>
                </SettingsSection>
                <SettingsSection>
                    <SettingsSectionInfo
                        title="Add Files & Prefix Patterns"
                        description="Datasets can consist of one of the following types: CSV, XML, and JSON. You may select individual files or folders of the same type."
                    />
                    <SettingsSectionContent>
                        <p>controls</p>
                    </SettingsSectionContent>
                </SettingsSection>
                <SettingsSection>
                    <SettingsSectionInfo
                        title="Files"
                        description="Upload from local or select files from the data source. You will be able to add files later, but the file settings are not be editable after file group is created."
                    >
                        <QuiBox display="flex" gap="sm" border="stroke-base" bg="background-base">
                            <QuiSwitchField name="firstRowIsColumnHeader" label="First row is column character" />
                        </QuiBox>
                    </SettingsSectionInfo>
                    <SettingsSectionContent>
                        <p>controls</p>
                    </SettingsSectionContent>
                </SettingsSection>
            </QuiBox>
        </QuiForm>
    );
}
