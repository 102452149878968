import { QuiBadge, QuiBadgeProps, QuiBox, QuiSpinner } from '@tonicai/ui-quinine';
import { JobStatus } from '../../types';

type JobStatusTagProps = Readonly<{
    status: JobStatus | string;
    size?: QuiBadgeProps['size'];
    ['data-test']?: string;
}>;

export function JobStatusTag({ status, size, ['data-test']: dataTest }: JobStatusTagProps) {
    const badgeSize = size ?? 'sm';
    const title = `${status}`;

    if (status === JobStatus.CANCELED) {
        return (
            <QuiBadge data-test={dataTest} size={badgeSize} variant="brand-orange">
                {title}
            </QuiBadge>
        );
    }

    if (status === JobStatus.FAILED) {
        return (
            <QuiBadge data-test={dataTest} size={badgeSize} variant="danger">
                {title}
            </QuiBadge>
        );
    }

    if (status === JobStatus.COMPLETED) {
        return (
            <QuiBadge data-test={dataTest} iconLeft="check" size={badgeSize} variant="positive-filled">
                {title}
            </QuiBadge>
        );
    }

    if (status === JobStatus.QUEUED) {
        return (
            <QuiBadge data-test={dataTest} size={badgeSize} variant="disabled">
                {title}
            </QuiBadge>
        );
    }

    if (status === JobStatus.RUNNING) {
        return (
            <QuiBadge size={badgeSize} variant="brand-royal">
                <QuiBox alignItems="center" display="inline-flex" gap="xs">
                    <QuiSpinner size="sm" />
                    <span data-test={dataTest}>{title}</span>
                </QuiBox>
            </QuiBadge>
        );
    }

    return (
        <QuiBadge data-test={dataTest} size={badgeSize} variant="default">
            {status}
        </QuiBadge>
    );
}
