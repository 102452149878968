import { usePdfOrImageFileData } from '../../hooks/usePdfOrImageFileData';
import { useTemplate } from '../../hooks/useTemplate';
import { useDataset } from '../../stores/datasets';
import { updateFilePdfRedactions } from '../../stores/filePdfRedaction';
import { ManualPdfRedactionType, PdfRedaction, PdfAndImageRedactionSource, PdfRedactionType, FileType } from '../../types';
import { PdfFilePagesPreview } from '../PdfPage/PdfFilePagesPreview';
import { PdfPageField } from '../PdfPage/PdfPageField';
import { PdfRedactionsControls } from '../PdfPage/PdfRedactionsControls';
import { NewAreaTypeSegmentedControl } from '../Templates/NewAreaTypeSegmentedControl';
import { Mutator } from 'final-form';
import mutators from 'final-form-arrays';
import { QuiBox, QuiSpinner, QuiForm, QuiSubmitButton, QuiModalContent, useQuiToasts } from '@tonicai/ui-quinine';
import { useState, useCallback, useEffect } from 'react';
import styles from '../Templates/TemplateDialog.module.scss';
import { FileTemplateField } from './FileTemplateField';
import { useScale } from '../Templates/useScale';
import { ZoomButtons } from '../ZoomButtons/ZoomButtons';
import { useBodyOverflowHidden } from '../../hooks/useBodyOverflowHidden';
import { useDatasetFile } from '../../stores/datasetFiles';
import { fetchFileBlob, useFileBlob } from '../../stores/fileBlobs';

const formMutators: Record<string, Mutator<PdfFileFormState>> = {
    ...mutators,
};

type PdfFileFormState = {
    templateId?: string;
    redactions: PdfRedaction[];
};

type PdfFileFormProps = Readonly<{
    fileId: string;
    datasetId: string;
    fileType: FileType;
}>;

export function PdfFileFormDialog({ fileId, datasetId, fileType }: PdfFileFormProps) {
    const [templateId, setTemplateId] = useState<string | undefined>(undefined);
    const fileBlob = useFileBlob(fileId);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pdfRedactionType, setPdfRedactionType] = useState<ManualPdfRedactionType>(PdfRedactionType.RemoveRedaction);
    const [editingRedactionIndex, setEditingRedactionIndex] = useState<number | undefined>(undefined);
    const addToast = useQuiToasts();
    const scale = useScale();

    const dataset = useDataset(datasetId);
    const file = useDatasetFile(fileId);

    useEffect(() => {
        fetchFileBlob(datasetId, fileId);
    }, [datasetId, fileId]);

    useBodyOverflowHidden(true);

    const { automaticJobRedactions, fileTemplateId, pdfWords, fileSpecificRedactions } = usePdfOrImageFileData(datasetId, fileId);

    useEffect(() => {
        setTemplateId(fileTemplateId);
    }, [fileTemplateId]);

    const { template } = useTemplate(datasetId, templateId);

    const onSubmit = useCallback(
        (values: PdfFileFormState) => {
            updateFilePdfRedactions(datasetId, fileId, values.redactions ?? [], values.templateId)
                .then(() => {
                    addToast({
                        title: 'Saved',
                        variant: 'positive',
                        timeout: 5000,
                    });
                })
                .catch(() => {
                    addToast({
                        title: 'Could not save',
                        variant: 'danger',
                    });
                });
        },
        [datasetId, fileId, addToast]
    );

    return (
        <QuiModalContent className={styles.templateDialogContent}>
            <QuiForm<PdfFileFormState>
                className={styles.templateDialogForm}
                initialValues={{
                    templateId: fileTemplateId,
                    redactions: fileSpecificRedactions,
                }}
                mutators={formMutators}
                onSubmit={onSubmit}
            >
                <div className={styles.layout}>
                    <div className={styles.leftColumn}>
                        <QuiBox display="flex" flexDirection="column" gap="lg">
                            <QuiBox weight="bold" text="display-xs">
                                {'File Redactions'}
                            </QuiBox>
                            <QuiBox>
                                {
                                    'Specify sensitive areas for this file or remove automatically detected areas or select from an already-created  template'
                                }
                            </QuiBox>
                            <FileTemplateField datasetId={datasetId} setFileTemplateId={setTemplateId} name={'templateId'} />
                            <PdfRedactionsControls
                                editingRedactionIndex={editingRedactionIndex}
                                setEditingRedactionIndex={setEditingRedactionIndex}
                                fieldName={'redactions'}
                                pageIndex={pageIndex}
                            />
                        </QuiBox>
                    </div>
                    <div className={styles.mainColumn}>
                        <div className={styles.mainColumnHeader}>
                            <NewAreaTypeSegmentedControl disabled={!fileId} onSelect={setPdfRedactionType} pdfRedactionType={pdfRedactionType} />
                            <QuiBox>{file?.fileName}</QuiBox>
                            <QuiBox>
                                <QuiSubmitButton>Save</QuiSubmitButton>
                            </QuiBox>
                        </div>
                        <div className={styles.mainColumnContent}>
                            <div className={styles.pagePreview}>
                                {!fileId && (
                                    <QuiBox justifyContent="center" display="flex" alignItems="center" padding="md">
                                        <QuiBox maxWidth="tablet">Select A File to begin creating your template</QuiBox>
                                    </QuiBox>
                                )}

                                {fileId && fileBlob.fetchStatus === 'loading' && (
                                    <QuiBox justifyContent="center" display="flex" alignItems="center" padding="md">
                                        <QuiSpinner />
                                    </QuiBox>
                                )}

                                <PdfPageField
                                    fileType={fileType}
                                    pdfRedactionSource={PdfAndImageRedactionSource.AttachedToFile}
                                    pageIndex={pageIndex}
                                    pdfRedactionType={pdfRedactionType}
                                    pdfBlob={fileBlob.fetchStatus === 'success' ? fileBlob.blob : undefined}
                                    existingRedactions={[...automaticJobRedactions, ...(template?.redactions ?? [])]}
                                    pdfWords={pdfWords}
                                    fieldName={'redactions'}
                                    generatorSetup={dataset?.generatorSetup}
                                    selectedFormRedactionIndex={editingRedactionIndex}
                                    pdfScale={scale.scale}
                                />
                            </div>
                        </div>
                        {fileId && <ZoomButtons className={styles.zoomButtons} {...scale} />}
                    </div>
                    <div className={styles.rightColumn}>
                        {fileBlob.fetchStatus === 'success' ? (
                            <PdfFilePagesPreview pageIndex={pageIndex} setPageIndex={setPageIndex} pdfBlob={fileBlob.blob} />
                        ) : null}
                    </div>
                </div>
            </QuiForm>
        </QuiModalContent>
    );
}
