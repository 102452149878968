import { FORM_ERROR } from 'final-form';
import { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import styles from '../../components/FileAdder/FileAdder.module.scss';
import { FormSection } from '../../components/FormSection/FormSection';
import { UploadLocalFilesButton } from '../../components/UploadFilesButton/UploadLocalFilesButton';
import {
    createExternalCredential,
    deleteExternalCredential,
    fetchExternalCredentials,
    updateExternalCredential,
} from '../../stores/externalCredentials';
import { ExternalCredentialType } from '../../types';
import { QuiBox, QuiButton, QuiFlexBoxColumn, QuiFlexH, QuiPasswordField, QuiSpinner, QuiTextField, useQuiToasts } from '@tonicai/ui-quinine';
import { EnvironmentModels, SENSITIVE_FIELD_FILLER } from '../../utils';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { environmentModelsAtom, useIsHostedProd } from '@/stores';
import { Table, TableCellProps, TableColumnConfig } from '@/components/Table/Table';
import { useAtomValue } from 'jotai';

type ExternalCredentialsFormState = {
    Loading: boolean;
    Saving?: boolean;
    HasGoogleServiceAccount?: boolean;
    GoogleServiceAccountFileName?: string;
    GoogleServiceAccountFileContents?: string;
    HasOpenApiKey?: boolean;
    OpenAiApiKey?: string;
};

const formatModelData = (models: EnvironmentModels) => {
    const multilingualMap: Record<string, string> = {
        ca: 'Catalan',
        zh: 'Chinese',
        hr: 'Croatia',
        da: 'Danish',
        nl: 'Dutch',
        fi: 'Finnish',
        fr: 'French',
        de: 'German',
        el: 'Greek',
        it: 'Italian',
        ja: 'Japanese',
        ko: 'Korean',
        lt: 'Lithuanian',
        mk: 'Macedonian',
        nb: 'Norwegian Bokmål',
        pl: 'Polish',
        pt: 'Portuguese',
        ro: 'Romanian',
        ru: 'Russian',
        sl: 'Slovenian',
        es: 'Spanish',
        sv: 'Swedish',
        uk: 'Ukrainian',
    };

    const mainModels = [
        {
            model: 'Textual NER Model',
            description: "Textual's NER models",
            location: models?.torch.gpuAvailable ? 'GPU/CPU' : 'CPU',
            version: models?.torch.libVersion,
            id: 'model',
        },
        {
            model: 'Date Synthesis',
            description: 'Seq2seq model for synthesizing complex dates',
            location: models?.dateSynthesis.runsOnGpu ? 'GPU/CPU' : 'CPU',
            version: '',
            id: 'date-synthesis',
        },
        {
            model: 'FastText',
            description: 'Model to auto-detect language',
            location: models?.fasttext.runsOnGpu ? 'GPU/CPU' : 'CPU',
            version: `${models?.fasttext.model}_${models?.fasttext.libVersion}`,
            id: 'fasttext',
        },
        {
            model: 'Auxilliary NER Model',
            description: 'Auxilliary model for improving labels',
            location: models?.spacy.auxModel.runsOnGpu ? 'GPU/CPU' : 'CPU',
            version: `${models?.spacy.auxModel.name}_${models?.spacy.auxModel.version}`,
            id: 'aux-model',
        },
    ];

    const multilingualModels = models.spacy.multilingualModels.map((model) => ({
        model: `${multilingualMap[model.language]} support`,
        description: `Support for the ${multilingualMap[model.language]} language`,
        location: model.runsOnGpu ? 'GPU/CPU' : 'CPU',
        version: `${model.name}_${model.version}`,
        id: `${model.name}_${model.version}_${model.language}`,
    }));

    return [...mainModels, ...multilingualModels];
};

type ModelTableData = {
    model: string;
    description: string;
    location: string;
    version: string;
    id: string;
};
const columns: TableColumnConfig<ModelTableData>[] = [
    {
        label: 'Model',
        Component({ data }: TableCellProps<ModelTableData>) {
            return <div>{data.model}</div>;
        },
        id: 'model',
    },
    {
        label: 'Description',
        Component({ data }: TableCellProps<ModelTableData>) {
            return <div>{data.description}</div>;
        },
        id: 'description',
    },
    {
        label: 'Location',
        Component({ data }: TableCellProps<ModelTableData>) {
            return <div>{data.location}</div>;
        },
        id: 'location',
    },
    {
        label: 'Version',
        Component({ data }: TableCellProps<ModelTableData>) {
            return <div>{data.version}</div>;
        },
        id: 'version',
    },
];
export function SystemSettings() {
    const isHostedProd = useIsHostedProd();
    const navigate = useNavigate();
    const [formState, setFormState] = useState<ExternalCredentialsFormState>({
        Loading: true,
    });
    const addToast = useQuiToasts();
    const environmentModels = useAtomValue(environmentModelsAtom);
    const modelTableData = environmentModels ? formatModelData(environmentModels) : [];

    useEffect(() => {
        fetchExternalCredentials().then((externalCredentials) => {
            const newState: ExternalCredentialsFormState = { Loading: false };
            const hasOpenAiApiKey = externalCredentials.filter((e) => e.credentialType === ExternalCredentialType.OpenAIKey).length > 0;
            newState.HasOpenApiKey = hasOpenAiApiKey;
            if (hasOpenAiApiKey) {
                const openAiCredential = externalCredentials.filter((e) => e.credentialType === ExternalCredentialType.OpenAIKey)[0];
                newState.OpenAiApiKey = openAiCredential.value;
            }
            const hasGoogleServiceAccount =
                externalCredentials.filter((e) => e.credentialType === ExternalCredentialType.GoogleServiceAccount).length > 0;
            newState.HasGoogleServiceAccount = hasGoogleServiceAccount;
            if (hasGoogleServiceAccount) {
                const googleServiceAccountCredential = externalCredentials.filter(
                    (e) => e.credentialType === ExternalCredentialType.GoogleServiceAccount
                )[0];
                newState.GoogleServiceAccountFileName = googleServiceAccountCredential.name;
                newState.GoogleServiceAccountFileContents = googleServiceAccountCredential.value;
            }
            setFormState((currentState) => ({ ...currentState, ...newState }));
        });
    }, []);

    const onSubmit = useCallback(
        async (values: ExternalCredentialsFormState) => {
            try {
                setFormState((currentState) => ({
                    ...currentState,
                    Saving: true,
                }));
                if (values.OpenAiApiKey && values.OpenAiApiKey !== SENSITIVE_FIELD_FILLER) {
                    if (values.HasOpenApiKey) {
                        await updateExternalCredential(ExternalCredentialType.OpenAIKey, 'OpenApiKey', values.OpenAiApiKey);
                    } else {
                        await createExternalCredential(ExternalCredentialType.OpenAIKey, 'OpenApiKey', values.OpenAiApiKey);
                    }
                } else if (values.HasOpenApiKey && !values.OpenAiApiKey) {
                    await deleteExternalCredential(ExternalCredentialType.OpenAIKey);
                }
                if (
                    values.GoogleServiceAccountFileName &&
                    values.GoogleServiceAccountFileContents &&
                    values.GoogleServiceAccountFileContents !== SENSITIVE_FIELD_FILLER
                ) {
                    if (values.HasGoogleServiceAccount) {
                        await updateExternalCredential(
                            ExternalCredentialType.GoogleServiceAccount,
                            values.GoogleServiceAccountFileName,
                            values.GoogleServiceAccountFileContents
                        );
                    } else {
                        await createExternalCredential(
                            ExternalCredentialType.GoogleServiceAccount,
                            values.GoogleServiceAccountFileName,
                            values.GoogleServiceAccountFileContents
                        );
                    }
                } else if (values.HasGoogleServiceAccount && !values.GoogleServiceAccountFileName) {
                    await deleteExternalCredential(ExternalCredentialType.GoogleServiceAccount);
                }
                navigate('/datasets');
            } catch (e) {
                const title = 'Something unexpected went wrong. Please contact support.';
                addToast({ title, variant: 'danger' });
                return {
                    [FORM_ERROR]: title,
                };
            } finally {
                setFormState((currentState) => ({
                    ...currentState,
                    Saving: false,
                }));
            }
        },
        [navigate, addToast]
    );
    return (
        <QuiFlexBoxColumn padding="md" gap="lg">
            <QuiBox>
                <PageTitle icon="settings" title="System Settings" />
            </QuiBox>
            <QuiBox>
                <Form<ExternalCredentialsFormState> onSubmit={onSubmit} initialValues={formState}>
                    {({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <FormSection
                                title="External LLM Credentials"
                                description="Provide Tonic Textual with the necessary credentials to connect to an LLM during custom model training. Only one is
                                needed."
                            >
                                {values.Loading ? (
                                    <QuiSpinner />
                                ) : (
                                    <QuiBox display="flex" flexDirection="column" gap="md">
                                        <QuiPasswordField
                                            name="OpenAiApiKey"
                                            label="OpenAI API Key"
                                            helperText="The API key provided to you by OpenAI"
                                            hideShowPasswordToggle={true}
                                        />
                                        <QuiFlexH align="bottom" gap="sm">
                                            <QuiBox display="flex" flexGrow="1">
                                                <QuiTextField
                                                    name="GoogleServiceAccountFileName"
                                                    disabled={true}
                                                    label="Google Service Account Json File"
                                                    style={{ width: '100%' }}
                                                />
                                            </QuiBox>
                                            <QuiButton
                                                type="button"
                                                iconLeft="x"
                                                onClick={() => {
                                                    setFormState((currentState) => ({
                                                        ...currentState,
                                                        GoogleServiceAccountFileName: undefined,
                                                        GoogleServiceAccountFileContents: undefined,
                                                    }));
                                                }}
                                            />
                                            <UploadLocalFilesButton
                                                variant="brand-purple"
                                                selectionMode="single"
                                                acceptedFileTypes="application/json"
                                                disabled={values.Saving || values.Loading}
                                                className={styles.uploadFilesButton}
                                                onFilesSelected={(files) => {
                                                    const file = files[0];
                                                    file.text().then((content) => {
                                                        setFormState((currentState) => ({
                                                            ...currentState,
                                                            GoogleServiceAccountFileName: file.name,
                                                            GoogleServiceAccountFileContents: content,
                                                        }));
                                                    });
                                                }}
                                            >
                                                Upload File
                                            </UploadLocalFilesButton>
                                        </QuiFlexH>
                                        <div>
                                            <QuiButton variant="brand-purple" type="submit" spinner={values.Saving}>
                                                Save
                                            </QuiButton>
                                        </div>
                                    </QuiBox>
                                )}
                            </FormSection>
                        </form>
                    )}
                </Form>
            </QuiBox>
            {!isHostedProd && (
                <QuiBox borderTop="stroke-base" padding="md none">
                    <FormSection title="Model Specifications" description="Information about the models that Textual uses to detect entities.">
                        <Table<ModelTableData> data={modelTableData} columns={columns} noDataMessage="No runs found" />
                    </FormSection>
                </QuiBox>
            )}
        </QuiFlexBoxColumn>
    );
}
