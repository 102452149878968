import { atom, useAtomValue } from 'jotai';
import type { FetchStatus, UserApiKey } from '../types';
import { globalStore } from './globalStore';
import { client } from '../services/HTTPClient';

// Atoms
const userAPIKeysAtom = atom<UserApiKey[]>([]);
const userAPIKeysFetchStatusAtom = atom<FetchStatus>('init');

// Hooks

export function useUserAPIKeys() {
    const userAPIKeys = useAtomValue(userAPIKeysAtom);
    const fetchStatus = useAtomValue(userAPIKeysFetchStatusAtom);

    return {
        userAPIKeys,
        fetchStatus,
    };
}

// Actions
export async function fetchUserApiKeys() {
    const fetchStatus = globalStore.get(userAPIKeysFetchStatusAtom);

    // Called twice in a row, so waiting for the first request to finish. We
    // could also use an AbortController like datasets use
    if (fetchStatus === 'loading' || fetchStatus === 'refreshing') return;

    globalStore.set(userAPIKeysFetchStatusAtom, fetchStatus === 'success' ? 'refreshing' : 'loading');

    try {
        const { data } = await client.get<UserApiKey[]>('/api/auth/apikeys');
        globalStore.set(userAPIKeysAtom, data);
        globalStore.set(userAPIKeysFetchStatusAtom, 'success');
    } catch (e) {
        globalStore.set(userAPIKeysFetchStatusAtom, 'error');
    }
}

export async function fetchUserApiKey(keyId: string, signal?: AbortSignal): Promise<UserApiKey> {
    const { data } = await client.get<UserApiKey>('/api/auth/apikeys/' + keyId, { signal });

    return data;
}

export async function revokeApiKey(id: string) {
    const apiKeys = globalStore.get(userAPIKeysAtom);
    if (apiKeys.length > 0) {
        const key = apiKeys.find((k) => k.id === id);
        if (key) {
            await client.delete(`/api/auth/apikeys/${key.id}`);
            await fetchUserApiKeys();
        }
    }
}

export async function createUserAPIKey(name: string) {
    const { data: key } = await client.post<UserApiKey>('/api/auth/apikeys', {
        name,
    });
    await fetchUserApiKeys();
    return key;
}
