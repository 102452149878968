import { useLocation } from 'react-router-dom';
import styles from './PageContainer.module.scss';
import { ReactNode } from 'react';

type PageContainerProps = Readonly<{
    children: ReactNode;
}>;

export function PageContainer({ children }: PageContainerProps) {
    const location = useLocation();
    //If we are on a request explorer page that isn't the request explorer home page we want a wider view so we can more easy visualize diffs
    if (location.pathname.trim().indexOf('request-explorer') > -1 && !location.pathname.trim().endsWith('request-explorer')) {
        return <div className={styles.pageContainerForRequestExplorer}>{children}</div>;
    } else {
        return <div className={styles.pageContainer}>{children}</div>;
    }
}
