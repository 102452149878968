import { PREVIEW_HIGHLIGHT_COLOR } from '@/constants';
import { useDatasetContext } from '@/contexts/DatasetContext';
import { fetchPagingNerRedactionContextsForPiiType } from '@/stores';
import { NerFileRedactionApiModel, PiiTypeEnum } from '@/types';
import { QuiBox, QuiButton, QuiIconEnum } from '@tonicai/ui-quinine';
import { Fragment, useState } from 'react';

const PAGE_SIZE = 11;
type Props = {
    piiType?: string;
    row: NerFileRedactionApiModel;
    datasetFileId: string;
};
export default function EntityManagerTableFilePage({ piiType, row, datasetFileId }: Props) {
    const { dataset } = useDatasetContext();

    const [pageNum, setPageNum] = useState(0);
    const continuationToken = row.pages[row.pages.length - 1]?.continuationToken;
    const isEmpty = row.pages.length === 0;

    const getRowBackground = (counter: number) => (counter % 2 === 0 ? '#f9f9f9' : '#fff');
    let counter = 0;
    return (
        <>
            {row.pages.map((page) => (
                <Fragment key={`${JSON.stringify(row)}`}>
                    {page.entities.map((entity, index) => {
                        counter++;
                        return (
                            <tr
                                style={{
                                    background: getRowBackground(counter),
                                }}
                                key={`${JSON.stringify(entity)}`}
                                {...(index === page.entities.length - 1 && {
                                    style: {
                                        borderBottom: '1px solid #e3e4ec',
                                        background: getRowBackground(counter),
                                    },
                                })}
                            >
                                {row.fileName.toLowerCase().endsWith('pdf') && (
                                    <td
                                        style={{
                                            borderBottom: '0px',
                                            maxWidth: '15px',
                                        }}
                                    >
                                        Page {page.pageNumber + 1}
                                    </td>
                                )}
                                {!row.fileName.toLowerCase().endsWith('pdf') && <td style={{ borderBottom: '0px' }} />}
                                <td
                                    style={{
                                        borderBottom: '0px',
                                        verticalAlign: 'top',
                                    }}
                                >
                                    {entity.entity}
                                </td>
                                <td
                                    style={{
                                        borderBottom: '0px',
                                        verticalAlign: 'top',
                                    }}
                                >
                                    {entity.head}
                                    <span
                                        style={{
                                            backgroundColor: PREVIEW_HIGHLIGHT_COLOR,
                                        }}
                                    >
                                        {entity.entity}
                                    </span>
                                    {entity.tail}
                                </td>
                                <td
                                    style={{
                                        borderBottom: '0px',
                                        verticalAlign: 'top',
                                    }}
                                >
                                    {entity.head}
                                    <span
                                        style={{
                                            backgroundColor: PREVIEW_HIGHLIGHT_COLOR,
                                        }}
                                    >
                                        {entity.transformation}
                                    </span>
                                    {entity.tail}
                                </td>
                            </tr>
                        );
                    })}
                </Fragment>
            ))}

            {isEmpty && (
                <tr>
                    <td colSpan={4}>
                        <QuiBox color="text-secondary" textAlign="center">
                            No entities found in this file
                        </QuiBox>
                    </td>
                </tr>
            )}

            {(pageNum > 0 || continuationToken) && (
                <tr>
                    <td colSpan={4}>
                        <QuiBox display="flex" gap="sm" alignItems="center" justifyContent="center">
                            {pageNum > 0 && (
                                <QuiButton
                                    iconLeft={QuiIconEnum.ArrowLeft}
                                    onClick={() => {
                                        const newPageNumber = pageNum - 1;
                                        setPageNum(newPageNumber);

                                        fetchPagingNerRedactionContextsForPiiType({
                                            datasetId: dataset.id,
                                            piiType: piiType as PiiTypeEnum,
                                            skip: newPageNumber * PAGE_SIZE,
                                            datasetFileId,
                                        });
                                    }}
                                />
                            )}
                            {pageNum + 1}
                            {continuationToken && (
                                <QuiButton
                                    iconRight={QuiIconEnum.ArrowRight}
                                    onClick={() => {
                                        setPageNum((current) => current + 1);

                                        fetchPagingNerRedactionContextsForPiiType({
                                            datasetId: dataset.id,
                                            piiType: piiType as PiiTypeEnum,
                                            skip: continuationToken!,
                                            datasetFileId,
                                        });
                                    }}
                                />
                            )}
                        </QuiBox>
                    </td>
                </tr>
            )}
        </>
    );
}
