import { QuiBox, QuiButton, QuiIcon, QuiTextField } from '@tonicai/ui-quinine';
import { TitleBox } from '../../components/TitleBox/TitleBox';
import { useFieldArray } from 'react-final-form-arrays';
import { useEffect, useMemo, useRef } from 'react';
import styles from './StringArrayField.module.scss';

type StringArrayFieldProps = Readonly<{
    name: string;
    title: string;
    noItemsFoundText: string;
    disableTextFields: boolean;
}>;

export function StringArrayField({ name, title, noItemsFoundText, disableTextFields }: StringArrayFieldProps) {
    const { fields } = useFieldArray<string>(name);
    const hasPatterns = Array.isArray(fields.value) && fields.value.length > 0;
    const focusLastIndex = useRef(false);
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const numFields = useMemo(() => fields?.length ?? 0, [fields]);

    useEffect(() => {
        if (focusLastIndex.current && inputRefs.current?.[numFields - 1]) {
            inputRefs.current[numFields - 1].focus();
            focusLastIndex.current = false;
        }
    }, [numFields]);

    return (
        <TitleBox title={title}>
            {hasPatterns ? (
                <QuiBox display="flex" gap="sm" flexDirection="column">
                    {fields.map((name, index) => (
                        <QuiBox key={name} display="flex" gap="md" alignItems="center">
                            <QuiBox flexGrow="1">
                                <QuiTextField
                                    className={styles.input}
                                    disabled={disableTextFields}
                                    name={name}
                                    ref={(el) => {
                                        if (el) {
                                            inputRefs.current[index] = el;
                                        }
                                    }}
                                />
                            </QuiBox>
                            <QuiButton
                                type="button"
                                iconLeft="trash"
                                onClick={() => {
                                    fields.remove(index);
                                }}
                            />
                        </QuiBox>
                    ))}
                </QuiBox>
            ) : (
                <QuiBox alignItems="center" display="flex" gap="xs" text="text-xs" color="text-subdued">
                    <QuiIcon icon="help-circle" />

                    <QuiBox>{noItemsFoundText}</QuiBox>
                </QuiBox>
            )}
        </TitleBox>
    );
}
