import { QuiBox, QuiButton, QuiPopover, QuiText, useQuiModal, useQuiToasts } from '@tonicai/ui-quinine';
import { cancelFileParseJob } from '../../stores/parse-jobs';

type CancelFileParseJobButtonProps = Readonly<{
    parseJobConfigId: string;
    fileParseJobId: string;
}>;

export function CancelFileParseJobButton({ parseJobConfigId, fileParseJobId }: CancelFileParseJobButtonProps) {
    const cancelPopover = useQuiModal();
    const addToast = useQuiToasts();
    return (
        <QuiPopover
            isOpen={cancelPopover.isOpen}
            onClose={cancelPopover.close}
            placement="bottom-end"
            content={
                <QuiBox display="flex" flexDirection="column" gap="sm" padding="md">
                    <QuiText size="text-sm">Are you sure you want to cancel this Pipeline run?</QuiText>
                    <QuiBox display="flex" gap="sm" justifyContent="end">
                        <QuiButton size="sm" onClick={cancelPopover.close}>
                            Don't Cancel
                        </QuiButton>
                        <QuiButton
                            size="sm"
                            variant="danger"
                            onClick={() => {
                                cancelFileParseJob(parseJobConfigId, fileParseJobId)
                                    .then(() => {
                                        addToast({
                                            title: 'Pipeline run cancelled',
                                            variant: 'positive',
                                        });
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                        addToast({
                                            title: 'Pipeline run failed to be cancelled',
                                            variant: 'danger',
                                        });
                                    })
                                    .finally(() => {
                                        cancelPopover.close();
                                    });
                            }}
                        >
                            Cancel Run
                        </QuiButton>
                    </QuiBox>
                </QuiBox>
            }
        >
            <QuiButton type="button" size="sm" iconLeft="x-circle" onClick={cancelPopover.open}>
                Cancel Run
            </QuiButton>
        </QuiPopover>
    );
}
