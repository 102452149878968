import { client } from '../services/HTTPClient';
import { PdfWord } from '../types';
import axios from 'axios';

const getBaseEndpointUrl = (datasetId: string, fileId: string) => `/api/dataset/${datasetId}/file/${fileId}/pdfwords`;

let fetchPdfFileWordsAbortController = new AbortController();

export async function fetchPdfFileWords(datasetId: string, fileId: string) {
    fetchPdfFileWordsAbortController.abort();
    fetchPdfFileWordsAbortController = new AbortController();

    return await client
        .get<PdfWord[]>(getBaseEndpointUrl(datasetId, fileId), {
            signal: fetchPdfFileWordsAbortController.signal,
        })
        .then(({ data }) => {
            return data;
        })
        .catch((e) => {
            if (axios.isCancel(e)) {
                //do nothing
                return [];
            } else {
                throw e;
            }
        });
}
