import { TextualIndustry } from '@/types';
import { requiredString } from '@/validation';
import { QuiBox, QuiSelectField, QuiTextField } from '@tonicai/ui-quinine';
import { useField } from 'react-final-form';

type TextualUseCaseFieldProps = Readonly<{
    name: string;
    label: string;
    otherIndustryName: string;
}>;

export function TextualIndustryField({ name, label, otherIndustryName }: TextualUseCaseFieldProps) {
    const industryField = useField<TextualIndustry>(name, {
        subscription: { value: true, touched: true, error: true },
        validate: (value) => {
            if (!value) {
                return 'Industry must be selected';
            }
            return undefined;
        },
    });

    return (
        <>
            <QuiSelectField
                name={name}
                label={label}
                options={[
                    { value: TextualIndustry.Education, label: 'Education' },
                    {
                        value: TextualIndustry.FinancialServices,
                        label: 'Financial Services',
                    },
                    { value: TextualIndustry.Healthcare, label: 'Healthcare' },
                    {
                        value: TextualIndustry.LegalServices,
                        label: 'Legal Services',
                    },
                    { value: TextualIndustry.Technology, label: 'Technology' },
                    {
                        value: TextualIndustry.RetailECommerce,
                        label: 'Retail & E-Commerce',
                    },
                    {
                        value: TextualIndustry.Telecommunications,
                        label: 'Telecommunications',
                    },
                    { value: TextualIndustry.Other, label: 'Other' },
                ]}
                placeholder="Select Industry"
            />
            {industryField.input.value == TextualIndustry.Other && (
                <QuiBox>
                    <QuiTextField placeholder={'Please Specify...'} name={otherIndustryName} validate={requiredString} />
                </QuiBox>
            )}
        </>
    );
}
